import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Stack } from "@mui/material";
import MonitorTimelineBody from "./MonitorTimelineBody";
import { useTrueBizApi } from "../../../api";

export interface Props {
  monitorDomain: UUID;
  focusedIssueId?: UUID | null | undefined;
}

export default function MonitorTimeline({
  monitorDomain,
  focusedIssueId,
}: Props) {
  const api = useTrueBizApi();

  const monitorQuery = useQuery({
    queryKey: ["getMonitor", monitorDomain],
    queryFn: () => api.getMonitoringEnrollment(monitorDomain),
    throwOnError: true,
  });

  if (monitorQuery.isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (monitorQuery.isError || !monitorQuery.data) {
    return <h1>There was an error error. Please try again shortly.</h1>;
  }

  return (
    <MonitorTimelineBody
      monitor={monitorQuery.data}
      focusedNotificationId={focusedIssueId}
    />
  );
}
