import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  Popover,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import StartIcon from "@mui/icons-material/Start";
import { matchSorter } from "match-sorter";

import { useTrueBizApi } from "../../../api";

export const useGetAllAssignees = () => {
  const api = useTrueBizApi();
  return useCallback(async () => {
    const assignees = await api.getAnomaliesMetaAssignableUsers();
    return assignees;
  }, [api]);
};

export interface Props {
  ids: string[];
  disabled?: boolean;
  onAssignIssues: (
    ids: string[],
    toUserId: string | null | undefined,
    toUserEmail: string | null | undefined
  ) => Promise<void>;
}

export default function AssignButton({
  ids,
  disabled = false,
  onAssignIssues,
}: Props) {
  const getAllAssignees = useGetAllAssignees();

  const [el, setEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [working, setWorking] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState("");

  const unassigned = useMemo(
    () => ({ id: "__unassigned__", label: "Unassigned" }),
    []
  );

  const allPeopleQuery = useQuery({
    queryKey: ["allPeople"],
    queryFn: getAllAssignees,
  });

  const allValues = useMemo(
    () => [
      unassigned,
      ...orderBy(
        (Array.isArray(allPeopleQuery.data) ? allPeopleQuery.data : []).map(
          (person) => ({
            id: person.id,
            label: person.email || `(User ${person.id})`,
          })
        ),
        "label"
      ),
    ],
    [unassigned, allPeopleQuery.data]
  );

  const assign = useCallback(async () => {
    setWorking(true);
    if (!selectedPerson) return;

    const userId =
      selectedPerson === "__unassigned__" ? undefined : selectedPerson;

    const email = !userId
      ? undefined
      : allValues.find((v) => v.id === selectedPerson)?.label || "...";

    try {
      await onAssignIssues(ids, userId, email);
      setWorking(false);
      setEl(null);
      setWorking(false);
      setSelectedPerson("");
      setSearchValue("");
    } catch (e) {
      setWorking(false);
    }
  }, [ids, selectedPerson, allValues, onAssignIssues]);

  const stickyValues: { id: string | null; label: string }[] = useMemo(
    () => [unassigned],
    [unassigned]
  );

  const filteredValues = useMemo(() => {
    const filtered: { id: string | null; label: string }[] = [];
    const searched = matchSorter(allValues || [], searchValue, {
      keys: ["label"],
    });

    for (const stickyValue of stickyValues || []) {
      if (searched.includes(stickyValue)) {
        filtered.push(stickyValue);
      }
    }

    return filtered.concat(searched.filter((f) => !stickyValues?.includes(f)));
  }, [allValues, stickyValues, searchValue]);

  useEffect(() => {
    if (!selectedPerson) return;

    // un-select an option if we filter it out of view
    if (!filteredValues.map((v) => v.id).includes(selectedPerson)) {
      setSelectedPerson("");
    }
  }, [selectedPerson, filteredValues]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{
          display: "flex",
          flexShrink: 0,
        }}
        startIcon={<StartIcon />}
        onClick={(e) => {
          setEl(e.currentTarget);
        }}
      >
        Assign selected
      </Button>
      <Popover
        open={!disabled && el != null}
        anchorEl={el}
        onClose={
          working
            ? undefined
            : () => {
                setEl(null);
              }
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ padding: "1em", width: "300px" }}>
          <Input
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search for people..."
            endAdornment={
              searchValue === "" ? null : (
                <IconButton onClick={() => setSearchValue("")}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            }
            style={{
              minHeight: "40px",
              width: "100%",
            }}
            disabled={working}
          />
          <Stack
            style={{
              minHeight: "50px",
              maxHeight: "175px",
              overflow: "scroll",
            }}
          >
            <RadioGroup
              value={selectedPerson}
              onChange={(e, value) => {
                setSelectedPerson(value);
              }}
            >
              {filteredValues.map((value) => (
                <FormControlLabel
                  key={value.id}
                  value={value.id}
                  control={<Radio />}
                  label={value.label}
                  disabled={working}
                />
              ))}
            </RadioGroup>
          </Stack>
          <Divider sx={{ marginBottom: "0.5em" }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setEl(null);
              }}
              disabled={working}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={working}
              onClick={() => {
                assign();
              }}
              disabled={!selectedPerson}
            >
              {selectedPerson === "__unassigned__" ? "Unassign" : "Assign"}
            </LoadingButton>
          </div>
        </Stack>
      </Popover>
    </>
  );
}
