import { pick } from "lodash";
import { useState, useEffect } from "react";

export const DEFAULT_SETTINGS = {
  allowConnectedEntitiesMonitor: false,
};

export function getAdminSettings() {
  let store = { ...DEFAULT_SETTINGS };

  try {
    const prev = localStorage.getItem("adminSettings");
    store =
      prev != null
        ? // drop old crufty keys if we removed them from DEFAULT_SETTINGS
          {
            ...store,
            ...pick({ ...JSON.parse(prev) }, Object.keys(DEFAULT_SETTINGS)),
          }
        : store;
  } catch {
    // pass
  }

  return store;
}

export function setAdminSettings(nextSettings: typeof DEFAULT_SETTINGS) {
  localStorage.setItem("adminSettings", JSON.stringify(nextSettings));
}

export default function useAdminSettings() {
  const [settings, setSettings] = useState(() => getAdminSettings());

  useEffect(() => {
    setAdminSettings(settings);
  }, [settings]);

  return [settings, setSettings] as const;
}
