import { Grid, Box, Typography, Avatar, Divider } from "@mui/material";
import dayjs from "dayjs";

import BusinessConnections from "./BusinessConnections";
import APIPerson from "../../../types/APIPerson";
import { DataSource } from "../../../types/APIApplication";
import Attribute from "../Attribute";
import { getLocationString } from "../KeyPeople";
import Tooltip from "../../Tooltip";

export default function SubmittedPerson({
  person,
  personBusinessConnections,
}: {
  person: APIPerson | null;
  personBusinessConnections: DataSource[] | null | undefined;
}) {
  return (
    <Box>
      <Box sx={{ height: 55 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18 }}
          variant="h6"
          component="span"
          marginBottom={1}
        >
          Person
        </Typography>

        <Typography sx={{ ml: 3 }} variant="subtitle1" component="span">
          {person?.name}
        </Typography>
      </Box>
      <Box sx={{ pr: 3, mb: 3 }}>
        <Divider />
      </Box>

      {person ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BusinessConnections sources={personBusinessConnections} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Attribute label="Job Title" failureText="Unknown">
              {person.job_title}
            </Attribute>
          </Grid>
          <Grid item xs={12} sm={4}>
            {person.profile_picture ? (
              <Avatar
                src={person.profile_picture}
                alt={person.name}
                sx={{ width: 60, height: 60 }}
              />
            ) : (
              <Avatar sx={{ width: 60, height: 60 }}>
                {person.name.slice(0, 1)}
              </Avatar>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Attribute label="Start Date" failureText="Unknown">
              {person.start_date
                ? dayjs(person.start_date).format("MMMM, YYYY")
                : "Unknown"}
            </Attribute>
          </Grid>
          <Grid item xs={12}>
            <Attribute label="Location" failureText="Unknown">
              {person.city ? (
                <Tooltip
                  title={getLocationString(person)}
                  placement="right"
                  arrow
                >
                  <Typography noWrap>{getLocationString(person)}</Typography>
                </Tooltip>
              ) : (
                "Unknown"
              )}
            </Attribute>
          </Grid>
        </Grid>
      ) : (
        <Box>Not sent</Box>
      )}
    </Box>
  );
}
