import { Badge, Stack, useTheme } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Fragment, useEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";
import { isSameDay, startOfToday } from "date-fns";
import IssueDetail from "./IssueDetail";
import Whisker from "./Whisker";
import { formatDate, maxDate } from "./utils";
import styles from "./MonitorTimelineBody.module.css";
import AssignButton from "./AssignButton";

import {
  APIMonitoringEnrollment,
  APIMonitorActivity,
  APIMonitorActivityType,
  APIMonitorUnresolvedAnomalyAssignment,
} from "../../../types/APIMonitoringEnrollment";

function Header({
  externalRefId,
  domain,
  unresolvedIssueCount = 0,
  prevRun,
  nextRun,
  assignment,
}: {
  externalRefId?: string | null | undefined;
  domain: string;
  unresolvedIssueCount?: number;
  prevRun?: ISODateTime | null | undefined;
  nextRun?: ISODateTime | null | undefined;
  assignment?: APIMonitorUnresolvedAnomalyAssignment | undefined;
}) {
  const theme = useTheme();

  return (
    <div className={styles.header}>
      <h2 style={{ marginTop: 0, lineHeight: 1, marginBottom: "0.25em" }}>
        <span>
          <a href={`//${domain}`} target="_blank" rel="noreferrer">
            {domain} <OpenInNewIcon fontSize="small" />
          </a>
        </span>
      </h2>
      {externalRefId && (
        <div
          style={{
            color: theme.palette.grey[700],
            fontSize: theme.typography.subtitle2.fontSize,
          }}
        >
          Reference ID <code>{externalRefId}</code>
        </div>
      )}
      <div className={styles.meta}>
        {unresolvedIssueCount > 0 && (
          <div
            style={{
              display: "flex",
              gap: "0.25em",
            }}
          >
            <Badge
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  border: "1px solid currentcolor",
                  position: "initial",
                  transform: "translateY(2px)",
                },
              }}
              badgeContent={unresolvedIssueCount}
            />
            <div>
              <strong>Open Issue{unresolvedIssueCount === 1 ? "" : "s"}</strong>
            </div>
          </div>
        )}
        <div className={styles.runInfo}>
          <div className={styles.next}>
            Next Run:{" "}
            {nextRun ? <strong>{formatDate(nextRun)}</strong> : "unscheduled"}
          </div>
          <div className={styles.previous}>
            Previous Run:{" "}
            {prevRun ? <strong>{formatDate(prevRun)}</strong> : "never"}
          </div>
        </div>
        {unresolvedIssueCount > 0 && (
          <div className={styles.assignedTo}>
            Assigned to:{" "}
            <AssignButton
              title="Assign issue"
              label={
                assignment?.assigned_to
                  ? assignment.assigned_to?.email || "A TrueBiz user"
                  : "unassigned"
              }
              currentAssigneeId={assignment?.assigned_to?.id}
              monitorDomain={domain}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export interface Props {
  monitor: APIMonitoringEnrollment;
  focusedNotificationId?: UUID | null | undefined;
}

export default function MonitorTimelineBody({
  monitor,
  focusedNotificationId,
}: Props) {
  // block lazy loading while we're scrolling, so we don't accidentally trigger a bunch of queries
  // while we're scrolling past
  const [suspendIssueLoading, setSuspendIssueLoading] = useState(
    focusedNotificationId != null
  );
  useEffect(() => {
    if (!focusedNotificationId) return;

    const el = document.getElementById(`issue_${focusedNotificationId}`);
    if (!el) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setSuspendIssueLoading(false);
          intersectionObserver.disconnect();
          return;
        }
      }
    });

    intersectionObserver.observe(el);

    el.scrollIntoView({
      behavior: "instant" as ScrollBehavior,
      block: "nearest",
    });
  }, [focusedNotificationId]);

  const groupedActivity = useMemo(() => {
    const grouped: APIMonitorActivity[][] = [];

    let currentGroup: APIMonitorActivity[] = [];
    for (const event of orderBy(monitor.activity, ["created_at"], ["desc"])) {
      if (!currentGroup.length) {
        currentGroup.push(event);
        continue;
      }

      if (isSameDay(currentGroup[0].created_at, event.created_at)) {
        currentGroup.push(event);
        continue;
      }

      // different day, start a new group
      grouped.push(currentGroup);
      currentGroup = [event];
    }

    if (currentGroup.length) {
      grouped.push(currentGroup);
    }

    return grouped;
  }, [monitor.activity]);

  return (
    <Stack minHeight="600px" alignItems="center" justifyContent="flex-start">
      <Header
        externalRefId={monitor.external_ref_id}
        domain={monitor.domain}
        prevRun={monitor.last_run}
        nextRun={
          monitor.next_run
            ? maxDate(monitor.next_run, startOfToday())
            : undefined
        }
        unresolvedIssueCount={monitor.unresolved_anomaly_count}
        assignment={monitor.unresolved_anomaly_assignments[0]}
      />
      {groupedActivity.map((eventGroup, i, eventGroups) => {
        const isLastEvent = i === eventGroups.length - 1;

        const systemEvents = orderBy(
          eventGroup.filter((e) => e.type !== APIMonitorActivityType.ENQUIRY),
          ["created_at"],
          ["desc"]
        );
        const anomalies = orderBy(
          eventGroup.flatMap((event) => {
            if (event.type !== APIMonitorActivityType.ENQUIRY) return [];
            const enquiryIssues = monitor.anomalies.filter((anomaly) =>
              event.anomaly_ids.includes(anomaly.id)
            );
            return enquiryIssues;
          }),
          ["created_at"],
          ["desc"]
        );

        if (!eventGroup.length) return null;
        if (!systemEvents.length && !anomalies.length) return null;
        const groupDate = eventGroup[0].created_at;

        return (
          <Stack key={groupDate} sx={{ width: "100%" }}>
            <div className={styles.timestamp}>{formatDate(groupDate)}</div>
            {anomalies.length > 0 && (
              <>
                <div className={styles.description}>
                  {anomalies.length} issue
                  {anomalies.length === 1 ? "" : "s"} detected
                </div>
              </>
            )}
            {anomalies.length > 0 && (
              <Stack sx={{ width: "100%" }}>
                <Stack>
                  {anomalies.map((anomaly, i, allAnomalies) => {
                    const isLastAnomaly = i === allAnomalies.length - 1;
                    return (
                      <Fragment key={anomaly.id}>
                        <Stack
                          alignItems="center"
                          sx={
                            anomaly.id === focusedNotificationId
                              ? {
                                  borderRadius: "10px",
                                  border: "1px solid #ccc",
                                  background: "rgba(30, 30, 30, 0.1)",
                                }
                              : {}
                          }
                          pt={1}
                        >
                          <IssueDetail
                            id={`issue_${anomaly.id}`}
                            issue={anomaly}
                            focused={anomaly.id === focusedNotificationId}
                            suspendLoading={
                              anomaly.id === focusedNotificationId
                                ? false
                                : suspendIssueLoading
                            }
                          />
                        </Stack>
                        {!isLastAnomaly && <Whisker />}
                      </Fragment>
                    );
                  })}
                </Stack>
                {!isLastEvent && <Whisker />}
              </Stack>
            )}
            {systemEvents.map((event) => {
              if (event.type === APIMonitorActivityType.ENROLLED) {
                return (
                  <Stack key={event.id} sx={{ width: "100%" }}>
                    <Stack>
                      <div className={styles.description}>
                        Enrolled in monitoring
                      </div>
                    </Stack>
                    {!isLastEvent && <Whisker />}
                  </Stack>
                );
              }

              if (event.type === APIMonitorActivityType.MODIFIED) {
                return (
                  <Stack key={event.id} sx={{ width: "100%" }}>
                    <Stack>
                      <div className={styles.description}>
                        Enrollment modified
                      </div>
                    </Stack>
                    {!isLastEvent && <Whisker />}
                  </Stack>
                );
              }

              if (event.type === APIMonitorActivityType.UNENROLLED) {
                return (
                  <Stack key={event.id} sx={{ width: "100%" }}>
                    <Stack>
                      <div className={styles.description}>
                        Enrolled in monitoring
                      </div>
                    </Stack>
                    {!isLastEvent && <Whisker />}
                  </Stack>
                );
              }

              if (process.env.NODE_ENV === "development") {
                console.error(
                  `Got unknown activity on monitor ${monitor.domain}. Type: ${
                    (event as any)?.type
                  }; Id: ${(event as any)?.id} !`
                );
              }

              return null;
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}
