import { Typography, Modal, Paper, Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import RiskToggle from "./RiskToggle";
import { MatchProfile } from "../types";

export default function TrafficModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: MatchProfile;
  updateMatchProfile: any;
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Edit Traffic Risks
            </Typography>
            <RiskToggle
              label="Enable high referring site traffic risk"
              description="This notice is triggered when a large percentage of traffic comes from a single source."
              value={matchProfile.enableReferringSite}
              onToggle={() =>
                updateMatchProfile({
                  enableReferringSite: !matchProfile.enableReferringSite,
                })
              }
            />
            <RiskToggle
              label="Enable traffic from unexpected countries risk"
              description="This notice indicates that a large portion of web traffic is coming from prohibited countries."
              value={matchProfile.enableCountryTraffic}
              onToggle={() =>
                updateMatchProfile({
                  enableCountryTraffic: !matchProfile.enableCountryTraffic,
                })
              }
            />
            <RiskToggle
              label="Enable high paid search rate risk"
              description="This notice indicates that the majority of traffic is coming from paid ads."
              value={matchProfile.enablePaidSearchRate}
              onToggle={() =>
                updateMatchProfile({
                  enablePaidSearchRate: !matchProfile.enablePaidSearchRate,
                })
              }
            />
            <RiskToggle
              label="Enable high website bounce rate risk"
              description="This notice indicates that the majority of traffic is leaving the site after just visiting one page."
              value={matchProfile.enableBounceRate}
              onToggle={() =>
                updateMatchProfile({
                  enableBounceRate: !matchProfile.enableBounceRate,
                })
              }
            />
            <RiskToggle
              label="Enable low average time on site risk"
              description="This notice indicates that users leave the site quickly, signaling low engagement."
              value={matchProfile.enableTimeOnSite}
              onToggle={() =>
                updateMatchProfile({
                  enableTimeOnSite: !matchProfile.enableTimeOnSite,
                })
              }
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
