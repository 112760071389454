import { useCallback, useState } from "react";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useQuery } from "@tanstack/react-query";
import Form from "./Form";
import Success from "./Success";
import Error from "./Error";

import { Prefill } from "../types";
import { useTrueBizApi } from "../../../../api";
import {
  APIMonitoringEnrollmentInput,
  APIMonitoringEnrollment,
} from "../../../../types/APIMonitoringEnrollment";
import useAdminSettings from "../../../../utilities/adminSettings";

type ViewState = "form" | "success" | "error";

export interface Props {
  prefill?: Partial<Prefill>;
  onCancel: () => void;
  onDone: () => void;
  onCreateMonitor: (
    formData: APIMonitoringEnrollmentInput
  ) => Promise<APIMonitoringEnrollment>;
}

export default function MonitorCreate({
  onCancel,
  onDone,
  onCreateMonitor,
  prefill,
}: Props) {
  const api = useTrueBizApi();
  const [settings] = useAdminSettings();

  const [currentView, setCurrentView] = useState<ViewState>("form");
  const [submittedDomain, setSubmittedDomain] = useState("");
  const [reason, setReason] = useState("");

  const matchProfilesQuery = useQuery({
    queryKey: ["getMatchProfiles"],
    queryFn: useCallback(() => api.getCompanyMatchProfiles(), [api]),
  });

  return (
    <Stack
      style={{
        width: "50vw",
        maxWidth: "425px",
        paddingLeft: "0.75em",
        paddingRight: "0.75em",
        paddingBottom: "0.75em",
        display: "flex",
        flex: 1,
      }}
    >
      <h2>
        <IconButton
          onClick={() => {
            onCancel();
          }}
        >
          <CloseIcon />
        </IconButton>
        New Enrollment
      </h2>
      {matchProfilesQuery.isLoading ? (
        <Stack
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        currentView === "form" && (
          <Form
            onCreateMonitor={async (formData) => onCreateMonitor(formData)}
            onCancel={() => {
              onCancel();
            }}
            onSuccess={(domain) => {
              setSubmittedDomain(domain);
              setCurrentView("success");
            }}
            onError={(domain, reason) => {
              setSubmittedDomain(domain);
              setReason(reason || "");
              setCurrentView("error");
            }}
            prefill={prefill}
            matchProfiles={matchProfilesQuery.data || []}
            withConnectedEntities={
              settings.allowConnectedEntitiesMonitor === true
            }
          />
        )
      )}
      {currentView === "success" && (
        <Success domain={submittedDomain} onDone={onDone} />
      )}
      {currentView === "error" && (
        <Error domain={submittedDomain} reason={reason} onDone={onDone} />
      )}
    </Stack>
  );
}
