import { Box, Chip } from "@mui/material";
import {
  Groups2,
  Web,
  AccountTree,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
} from "@mui/icons-material";

import Attribute from "../Attribute";
import Tooltip from "../../Tooltip";
import { DataSource } from "../../../types/APIApplication";
import { humanizeString } from "../../../utilities/formatting";

function SourceChip({ source }: { source: DataSource }) {
  const getIcon = (dataSource: DataSource) => {
    switch (dataSource) {
      case DataSource.SOCIAL:
        return <Groups2 fontSize="small" />;
      case DataSource.WEBSITE:
        return <Web fontSize="small" />;
      case DataSource.OTHER:
        return <AccountTree fontSize="small" />;
    }
  };

  return (
    <Chip
      key={source}
      icon={getIcon(source)}
      label={humanizeString(source)}
      variant="outlined"
      sx={{ mr: 1, mt: 0.5, pl: 1 }}
    />
  );
}

export default function BusinessConnections({
  sources,
}: {
  sources: DataSource[] | null | undefined;
}) {
  return (
    <Box sx={{ height: 60 }}>
      <Attribute
        label="Business Connections"
        failureText="No Connections Found"
        labelIcon={
          <Tooltip
            title="Was this record found associated with the business in another context?"
            placement="right"
            arrow
          >
            <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
          </Tooltip>
        }
      >
        {sources?.length ? (
          sources?.map((source) => <SourceChip key={source} source={source} />)
        ) : sources == null ? (
          <div></div>
        ) : (
          <div>No Connections Found</div>
        )}
      </Attribute>
    </Box>
  );
}
