import { APIAnomalyUser } from "../../../types/APIAnomaly";
import { APIMonitorUnresolvedAnomalyAssignment } from "../../../types/APIMonitoringEnrollment";

export type SelectionAction = "assign" | "export" | "delete" | "edit";

export type TableColumn =
  | "alerts"
  | "assigned_to"
  | "domain"
  | "id"
  | "status"
  | "last_run"
  | "monitor_types"
  | "next_run";

export type TableOrderBy =
  | "alerts"
  | "-alerts"
  | "assigned_to"
  | "-assigned_to"
  | "domain"
  | "-domain"
  | "last_run"
  | "-last_run"
  | "monitor_types"
  | "-monitor_types"
  | "next_run"
  | "-next_run"
  | "status"
  | "-status";

export enum TableStatus {
  ALERT = "Alert",
  ALERT_AND_PENDING = "Alerts/Processing",
  ALL_CLEAR = "No Alerts",
  PENDING = "Processing",
}

export enum TableMonitorType {
  CONNECTED_ENTITIES = "Connected Entities",
  BUSINESS_CLOSURE = "Business Closure",
  WEBSITE_CONTENT = "Website Content",
}

export type TableFilterBy =
  | { column: "alerts"; operator: "in"; value: string[] }
  | { column: "assigned_to"; operator: "in"; value: string[] }
  | { column: "domain"; operator: "in"; value: string[] }
  | { column: "status"; operator: "in"; value: TableStatus[] }
  | { column: "last_run"; operator: "gte"; value: Date }
  | { column: "last_run"; operator: "gt"; value: Date }
  | { column: "last_run"; operator: "lte"; value: Date }
  | { column: "last_run"; operator: "lt"; value: Date }
  | { column: "monitor_types"; operator: "in"; value: TableMonitorType[] }
  | { column: "next_run"; operator: "gte"; value: Date }
  | { column: "next_run"; operator: "gt"; value: Date }
  | { column: "next_run"; operator: "lte"; value: Date }
  | { column: "next_run"; operator: "lt"; value: Date };

export interface TableRow {
  alerts: string[];
  assigned_to: APIMonitorUnresolvedAnomalyAssignment[] | null | undefined;
  domain: string | undefined;
  id: UUID;
  last_run?: ISODateTime | null | undefined;
  monitor_types: TableMonitorType[];
  next_run?: ISODateTime | null | undefined;
  status: TableStatus | null | undefined;
}

export enum ExportStatus {
  OPEN = "Open",
  ASSIGNED = "Assigned",
  RESOLVED = "Resolved",
}
export interface ExportRow {
  // TODO: re-factor this to be integrated with the main types
  alerts: string[];
  assignee: APIAnomalyUser | null | undefined;
  date: ISODateTime;
  domain: string | undefined;
  id: UUID;
  status: ExportStatus | null | undefined;

  // only used for export
  messages: string[];
  acknowledged_at: ISODateTime | null | undefined;
  acknowledged_by: string | null | undefined; // email
  acknowledged_reason: string | null | undefined;
  acknowledged_notes: string | null | undefined;
}
