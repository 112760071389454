import { Box, Grid, Typography, Divider } from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";

import BusinessConnections from "./BusinessConnections";
import BooleanAttribute from "./../BooleanAttribute";
import Attribute from "./../Attribute";
import Tooltip from "../../Tooltip";
import APIAddress from "../../../types/APIAddress";
import { humanizeString } from "../../../utilities/formatting";
import { DataSource } from "../../../types/APIApplication";

export default function SubmittedAddress({
  address,
  addressBusinessConnections,
}: {
  address: APIAddress | null | undefined;
  addressBusinessConnections: DataSource[] | null | undefined;
}) {
  let addr1 = "";
  let addr2 = "";
  if (address) {
    addr1 += address.line_1;
    if (address.line_2) {
      addr1 += address.line_2;
    }
    if (address.city) {
      addr2 += address.city;
    }
    if (address.state_province) {
      if (addr2.length) {
        addr2 += ", ";
      }
      addr2 += address.state_province;
    }
    if (address.postal_code) {
      if (addr2.length) {
        addr2 += " ";
      }
      addr2 += address.postal_code;
    }
    if (address.country) {
      if (addr2.length) {
        addr2 += ", ";
      }
      addr2 += address.country;
    }
  }

  return (
    <Box>
      <Box sx={{ height: 55 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18, display: "inline-block", mb: 0 }}
          variant="subtitle1"
        >
          Address
        </Typography>

        {address && (
          <>
            <Tooltip title={address.full_address} placement="right" arrow>
              <Typography
                sx={{ ml: 2, mb: -1.1, display: "inline-block", width: "60%" }}
                noWrap
                variant="subtitle1"
              >
                {addr1}
              </Typography>
            </Tooltip>
            <Tooltip title={address.full_address} placement="right" arrow>
              <Typography noWrap variant="subtitle2">
                {addr2}
              </Typography>
            </Tooltip>
          </>
        )}
      </Box>
      <Box sx={{ pr: 3, mb: 3 }}>
        <Divider />
      </Box>

      {address ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BusinessConnections sources={addressBusinessConnections} />
          </Grid>
          <Grid item xs={12}>
            <BooleanAttribute
              label="Valid?"
              labelIcon={
                <Tooltip
                  title="Is the address known to and confirmed as valid by the postal service?"
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              // only show validity signals, no validation failures
              value={address.is_valid}
              trueIsPositive
              failureText="Unknown"
            />
          </Grid>
          <Grid item xs={12}>
            <Attribute
              label="Postal flags"
              labelIcon={
                <Tooltip title={"Not yet implemented"} placement="right" arrow>
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              failureText="Unknown"
            >
              {address?.postal_service_flags?.map(humanizeString)?.join(", ") ||
                "No Flags Found"}
            </Attribute>
          </Grid>
          <Grid item xs={12} sm={12}>
            <BooleanAttribute
              label="Is shared with registered agent?"
              labelIcon={
                <Tooltip title={"Not yet implemented"} placement="right" arrow>
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              value={address.is_shared_with_registered_agent}
              trueIsPositive={false}
              failureText="Unknown"
            />
          </Grid>
        </Grid>
      ) : (
        <Box>Not sent</Box>
      )}
    </Box>
  );
}
