import { useCallback, useState, useRef } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Paper, Popover, Divider, Button } from "@mui/material";

import FilterControlContext from "./context";

export interface Props {
  disabled?: boolean;
  active?: boolean;
  onReset?: () => void;
  onClose?: () => void;
  children: React.ReactNode;
}

export default function ColumnFilterSort({
  children,
  active,
  onReset,
  disabled,
  onClose,
}: Props) {
  const [el, setEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const applyFinalizers = useRef<Set<() => void>>(new Set());

  const apply = useCallback(() => {
    for (const finalizer of applyFinalizers.current) {
      finalizer();
    }

    setIsOpen(false);
    setEl(null);
    onClose?.();
  }, [onClose]);

  const cancel = useCallback(() => {
    setIsOpen(false);
    setEl(null);
  }, []);

  return (
    <FilterControlContext.Provider value={{ applyFinalizers }}>
      <IconButton
        title="Sort / filter"
        onClick={(e) => {
          setEl(e.currentTarget);
          setIsOpen((o) => !o);
        }}
        disabled={disabled}
      >
        {active ? <FilterAltIcon color="primary" /> : <FilterListIcon />}
      </IconButton>
      <Popover
        open={disabled ? false : isOpen}
        anchorEl={el}
        onClose={cancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper style={{ padding: "1em", width: "250px" }}>
          {active && onReset && (
            <>
              <Button
                variant="outlined"
                title="Reset sort / filter"
                startIcon={<RestartAltIcon />}
                onClick={() => {
                  cancel();
                  onReset();
                }}
                sx={{ mb: 2 }}
              >
                Reset
              </Button>
              <Divider />
            </>
          )}
          {children}
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1em",
            }}
          >
            <Button onClick={cancel} variant="outlined">
              Cancel
            </Button>
            <Button onClick={apply} variant="contained">
              Apply
            </Button>
          </div>
        </Paper>
      </Popover>
    </FilterControlContext.Provider>
  );
}
