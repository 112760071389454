import queryString from "query-string";
import { BaseApi } from "./base";

import accountInformationResponse from "../fixtures/accountInformationResponse.json";
import createMatchProfileResponse from "../fixtures/createMatchProfileResponse.json";
import offlineCompanyResponse from "../fixtures/offlineCompanyResponse.json";
import companyMatchProfiles from "../fixtures/companyMatchProfiles.json";
import pagedCompanyHistory from "../fixtures/pagedCompanyHistory.json";
import pagedBlockedDomains from "../fixtures/pagedBlockedDomains.json";
import websiteStatusResponse from "../fixtures/websiteStatusResponse.json";
import openApiSchema from "../fixtures/openApiSchema.json";
import accountSearchStats from "../fixtures/accountSearchStats.json";
import domainFinderResponse from "../fixtures/domainFinderResponse.json";
import APIBlockedDomainInput from "../types/APIBlockedDomainInput";
import APIAccountSearchSummary from "../types/APIAccountSearchSummary";
import { dataUrlToBlob } from "../utilities/testing";
import APISearchQuery from "../types/APISearchQuery";
import APICompanyMatchProfile from "../types/APICompanyMatchProfile";
import APIAccountInfo from "../types/APIAccountInfo";
import APICompany from "../types/APICompany";
import APIMonitoringAnomaly, {
  APIAnomalyFilterBy,
  APIAnomalyLink,
  APIAnomalyOrderBy,
  APIAnomalyResolutionType,
  APIAnomalyStatus,
  APIAnomalyUser,
} from "../types/APIAnomaly";
import {
  APIMonitoringEnrollment,
  APIMonitoringEnrollmentFilterBy,
  APIMonitoringEnrollmentOrderBy,
  APIMonitorStatusType,
  APIMonitorType,
} from "../types/APIMonitoringEnrollment";
import {
  MatchProfile,
  MatchProfileOverrides,
} from "../components/MatchProfile/types";

const screenshot = require("../fixtures/screenshot.png");

export class TrueBizApi extends BaseApi {
  rootUrl = `${process.env.REACT_APP_TRUEBIZ_API_HOSTNAME}`;

  domainLookup(query: APISearchQuery) {
    return this.authenticated()
      .json()
      .stubbedWith(offlineCompanyResponse, { delayMs: 5_000 })
      .post(`${this.rootUrl}/company/search`, query) as Promise<APICompany>;
  }

  schemaLookup() {
    return this.anonymous()
      .json()
      .stubbedWith(openApiSchema, { delayMs: 250 })
      .get(`${this.rootUrl}/openapi.json`);
  }

  accountLookup() {
    return this.authenticated()
      .json()
      .stubbedWith(accountInformationResponse, { delayMs: 1_000 })
      .get(`${this.rootUrl}/account/`) as Promise<APIAccountInfo>;
  }

  webhookLookup(authUrl: string) {
    // This will return a non-response, which should trigger an alert in the UI.
    return this.authenticated()
      .json()
      .stubbedWith({}, { delayMs: 250 })
      .get(authUrl) as { dashboard_url?: string };
  }

  getDomainFinder(
    email: string | null,
    phone: string | null,
    businessName: string | null,
    addressLine1: string | null,
    addressLine2: string | null,
    city: string | null,
    stateProvince: string | null,
    postalCode: string | null,
    country: string | null
  ) {
    const queryParams = new URLSearchParams({
      email: String(email),
      phone: String(phone),
      business_name: String(businessName),
      address_line_1: String(addressLine1),
      address_line_2: String(addressLine2),
      city: String(city),
      state_province: String(stateProvince),
      postal_code: String(postalCode),
      country: String(country),
    });

    return this.authenticated()
      .json()
      .stubbedWith(domainFinderResponse)
      .get(`${this.rootUrl}/domain_finder/?${queryParams.toString()}`);
  }

  getCompanyLookups(
    searchTerm: string,
    minDate: string | null,
    maxDate: string | null,
    offset: number,
    limit: number
  ) {
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
      ...(minDate ? { min_datetime: minDate } : {}),
      ...(maxDate ? { max_datetime: maxDate } : {}),
      ...(searchTerm ? { search_term: searchTerm } : {}),
    });

    return this.authenticated()
      .json()
      .stubbedWith(pagedCompanyHistory)
      .get(`${this.rootUrl}/history/company?${queryParams.toString()}`);
  }

  getCompanyLookup(requestId: string) {
    return this.authenticated()
      .json()
      .stubbedWith(pagedCompanyHistory.items[0])
      .get(`${this.rootUrl}/history/company/${requestId}`);
  }

  getCompanyLookupPdfSummary(requestId: string) {
    return this.authenticated()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" }) // TODO: create empty pdf fixture
      .get(`${this.rootUrl}/pdf_summary/${requestId}`, {
        unwrapResponse: false,
      })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, blob: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          blob: blob || null,
        };
      });
  }

  getPassfortCompanyLookup(
    requestId: string,
    passfortParameters: URLSearchParams | null
  ) {
    const url = new URL(
      `${this.rootUrl}/passfort/company/${requestId}?` + passfortParameters
    );

    return this.anonymous()
      .json()
      .stubbedWith(pagedCompanyHistory.items[0])
      .get(url);
  }

  getAuthRelayCompanyLookup(
    requestId: string,
    relayParameters: URLSearchParams | null
  ) {
    const url = new URL(
      `${this.rootUrl}/relay/company/${requestId}?` + relayParameters
    );

    return this.anonymous()
      .json()
      .stubbedWith(pagedCompanyHistory.items[0])
      .get(url);
  }

  getScreenshot(
    src: string,
    signal: AbortSignal
  ): Promise<{ status: number; url: string | null }> {
    return this.authenticated()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" })
      .get(src, { unwrapResponse: false, fetchOptions: { signal } })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, url: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          url: blob ? window.URL.createObjectURL(blob) : null,
        };
      });
  }

  getPassfortScreenshot(
    src: string,
    passfortParameters: URLSearchParams | null,
    passfortRequestId: string | null,
    signal: AbortSignal
  ): Promise<{ status: number; url: string | null }> {
    const url = new URL(src);
    if (!url.pathname.toString().startsWith("/api/v1/passfort/")) {
      url.pathname = url.pathname.replace("/api/v1/", "/api/v1/passfort/");
    }
    url.pathname += `/${passfortRequestId}`;
    url.search = new URLSearchParams({
      ...Object.fromEntries(url.searchParams),
      ...(passfortParameters ? Object.fromEntries(passfortParameters) : {}),
    }).toString();

    return this.anonymous()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" })
      .get(url, { unwrapResponse: false, fetchOptions: { signal } })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, url: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          url: blob ? window.URL.createObjectURL(blob) : null,
        };
      });
  }

  getRelayScreenshot(
    src: string,
    relayParameters: URLSearchParams | null,
    relayRequestId: string | null,
    signal: AbortSignal
  ): Promise<{ status: number; url: string | null }> {
    const url = new URL(src);
    if (!url.pathname.toString().startsWith("/api/v1/relay/")) {
      url.pathname = url.pathname.replace("/api/v1/", "/api/v1/relay/");
    }
    url.pathname += `/${relayRequestId}`;
    url.search = new URLSearchParams({
      ...Object.fromEntries(url.searchParams),
      ...(relayParameters ? Object.fromEntries(relayParameters) : {}),
    }).toString();

    return this.anonymous()
      .untyped()
      .stubbedWith(() => dataUrlToBlob(screenshot), { type: "blob" })
      .get(url, { unwrapResponse: false, fetchOptions: { signal } })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          return { status: res.status, url: null };
        }

        const blob = await res.blob();

        return {
          status: res.status,
          url: blob ? window.URL.createObjectURL(blob) : null,
        };
      });
  }

  getBlockedDomains(offset: number, limit: number) {
    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(limit),
    });

    const url = `${this.rootUrl}/company/block?${queryParams.toString()}`;

    return this.authenticated()
      .json()
      .stubbedWith(pagedBlockedDomains)
      .get(url);
  }

  deleteBlockedDomain(domain: string) {
    return this.authenticated()
      .json()
      .stubbedWith({ message: `Block deleted for ${domain}.` })
      .delete(`${this.rootUrl}/company/block`, { domain: domain });
  }

  createBlockedDomain(formData: APIBlockedDomainInput) {
    return this.authenticated()
      .json()
      .stubbedWith({ message: `Block created for ${formData.domain}.` })
      .post(`${this.rootUrl}/company/block`, formData);
  }

  getAnomalies({
    limit,
    offset = 0,
    orderBy = [],
    filterBy = [],
    search = "",
  }: {
    limit?: number;
    offset?: number;
    orderBy?: APIAnomalyOrderBy[];
    filterBy?: APIAnomalyFilterBy[];
    search?: string;
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }

            // we need a special empty array value to differentiate from None
            const qsValue = !Array.isArray(value)
              ? value
              : value.length === 0
              ? ["[]"]
              : value;

            if (operator === "in") {
              return [column, qsValue]; // in is now implied
            }

            return [`${column}_${operator}`, qsValue];
          })
        ),
        ...(search ? { search } : {}),
        ...(limit ? { limit } : {}),
        ...(offset ? { offset } : {}),
        ...(orderBy ? { order_by: orderBy } : {}),
      },
      {
        arrayFormat: "none", // repeated keys
        encode: true, // make sure things like emails get % encoded
      }
    );

    return this.authenticated()
      .json()
      .stubbedWith({
        anomalies: [],
        next_anomalies_link: null,
        prev_anomalies_link: null,
        count: 0,
        limit: 0,
        offset: 0,
      })
      .get(`${this.rootUrl}/monitoring/anomalies?${qs}`) as Promise<{
      anomalies: APIMonitoringAnomaly[];
      count: number;
      limit: number;
      offset: number;
      next_anomalies_link?: APIAnomalyLink | null | undefined;
      prev_anomalies_link?: APIAnomalyLink | null | undefined;
    }>;
  }

  getAnomaly(anomalyId: UUID) {
    return this.authenticated()
      .json()
      .stubbedWith({})
      .get(
        `${this.rootUrl}/monitoring/anomalies/${anomalyId}`
      ) as Promise<APIMonitoringAnomaly>;
  }

  getAnomaliesMetaUniqueValuesForDomain({
    filterBy = [],
  }: {
    filterBy?: APIAnomalyFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/anomalies/_meta/unique_values_for/domain?${qs}`
      ) as Promise<string[]>;
  }

  getAnomaliesMetaUniqueValuesForStatus({
    filterBy = [],
  }: {
    filterBy?: APIAnomalyFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/anomalies/_meta/unique_values_for/status?${qs}`
      ) as Promise<APIAnomalyStatus[]>;
  }

  getAnomaliesMetaUniqueValuesForAlerts({
    filterBy = [],
  }: {
    filterBy?: APIAnomalyFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/anomalies/_meta/unique_values_for/alerts?${qs}`
      ) as Promise<{ type: string; display: string }[]>;
  }

  getAnomaliesMetaAssignableUsers() {
    return this.authenticated()
      .json()
      .stubbedWith([
        {
          id: "00000000-0000-0000-0000-000000000000",
          email: "schmee@truebiz.io",
          full_name: null,
        },
      ])
      .get(
        `${this.rootUrl}/monitoring/anomalies/_meta/assignable_users`
      ) as Promise<APIAnomalyUser[]>;
  }

  anomaliesMetaBulkAssignToUsers(
    anomalyIds: UUID[],
    assignedToId: UUID | null | undefined
  ) {
    return this.authenticated()
      .json()
      .stubbedWith([])
      .put(`${this.rootUrl}/monitoring/anomalies/_meta/bulk/assign_to_user`, {
        anomaly_ids: anomalyIds,
        assigned_to_id: assignedToId,
      });
  }

  getMonitoringEnrollments({
    limit,
    offset = 0,
    orderBy = [],
    filterBy = [],
    search = "",
  }: {
    limit?: number;
    offset?: number;
    orderBy?: APIMonitoringEnrollmentOrderBy[];
    filterBy?: APIMonitoringEnrollmentFilterBy[];
    search?: string;
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }

            // we need a special empty array value to differentiate from None
            const qsValue = !Array.isArray(value)
              ? value
              : value.length === 0
              ? ["[]"]
              : value;

            if (operator === "in") {
              return [column, qsValue]; // in is now implied
            }

            return [`${column}_${operator}`, qsValue];
          })
        ),
        ...(search ? { search } : {}),
        ...(limit ? { limit } : {}),
        ...(offset ? { offset } : {}),
        ...(orderBy ? { order_by: orderBy } : {}),
      },
      {
        arrayFormat: "none", // repeated keys
        encode: true, // make sure things like emails get % encoded
      }
    );

    return this.authenticated()
      .json()
      .stubbedWith({
        website_content_monitors: [],
        next_anomalies_link: null,
        prev_anomalies_link: null,
        count: 0,
        limit: 0,
        offset: 0,
      })
      .get(`${this.rootUrl}/monitoring/enrollments/?${qs}`) as Promise<{
      enrollments: APIMonitoringEnrollment[];
      count: number;
      limit: number;
      offset: number;
      next_website_content_monitors_link?: APIAnomalyLink | null | undefined;
      prev_website_content_monitors_link?: APIAnomalyLink | null | undefined;
    }>;
  }

  getMonitoringEnrollmentsMetaUniqueValuesForDomain({
    filterBy = [],
  }: {
    filterBy: APIMonitoringEnrollmentFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/enrollments/_meta/unique_values_for/domain?${qs}`
      ) as Promise<string[]>;
  }

  getMonitoringEnrollmentsMetaUniqueValuesForMonitorTypes({
    filterBy = [],
  }: {
    filterBy: APIMonitoringEnrollmentFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/enrollments/_meta/unique_values_for/monitor_types?${qs}`
      ) as Promise<APIMonitorType[]>;
  }

  getMonitoringEnrollmentsMetaUniqueValuesForStatus({
    filterBy = [],
  }: {
    filterBy: APIMonitoringEnrollmentFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/enrollments/_meta/unique_values_for/status?${qs}`
      ) as Promise<APIMonitorStatusType[]>;
  }

  getMonitoringEnrollmentsMetaUniqueValuesForAlerts({
    filterBy = [],
  }: {
    filterBy: APIMonitoringEnrollmentFilterBy[];
  }) {
    const qs = queryString.stringify(
      {
        ...Object.fromEntries(
          filterBy.map(({ column, operator, value }) => {
            if (operator === "eq") {
              return [column, value];
            }
            if (operator === "in") {
              return [column, value];
            }
            return [`${column}_${operator}`, value];
          })
        ),
      },
      {
        arrayFormat: "none", // repeated keys,
        encode: true, // make sure things like emails get % encoded
      }
    );
    return this.authenticated()
      .json()
      .stubbedWith([])
      .get(
        `${this.rootUrl}/monitoring/enrollments/_meta/unique_values_for/domain?${qs}`
      ) as Promise<string[]>;
  }

  monitoringEnrollmentsMetaBulkAssignToUser(
    monitorDomains: string[],
    assignedToId: UUID | null | undefined
  ) {
    return this.authenticated()
      .json()
      .stubbedWith([])
      .put(`${this.rootUrl}/monitoring/enrollments/_meta/bulk/assign_to_user`, {
        monitor_domains: monitorDomains,
        assigned_to_id: assignedToId,
      });
  }

  getMonitoringEnrollment(monitor_domain: string) {
    return this.authenticated()
      .json()
      .stubbedWith({})
      .get(
        `${this.rootUrl}/monitoring/enrollments/${monitor_domain}/`
      ) as Promise<APIMonitoringEnrollment>;
  }

  unEnrollMonitoringDomain(payload: { domain: string }) {
    return this.authenticated()
      .json()
      .stubbedWith(payload)
      .post(`${this.rootUrl}/monitoring/enrollments/un_enroll/`, payload, {
        unwrapResponse: false,
      }) as Promise<void>;
  }

  enrollMonitoringDomain(payload: {
    domain: string;
    monitor_types: APIMonitorType[];
    external_ref_id?: string | null | undefined;
    profile_id?: UUID | null | undefined;
  }) {
    return this.authenticated()
      .json()
      .stubbedWith(payload)
      .post(
        `${this.rootUrl}/monitoring/enrollments/enroll/`,
        payload
      ) as Promise<APIMonitoringEnrollment>;
  }

  reEnrollMonitoringDomain(payload: {
    domain: string;
    external_ref_id?: string | null | undefined;
    profile_id: UUID | null | undefined;
  }): Promise<APIMonitoringEnrollment> {
    throw new Error("Not implemented");
  }

  addAnomalyComment(anomalyId: UUID, message: string) {
    return this.authenticated()
      .json()
      .stubbedWith({})
      .post(`${this.rootUrl}/monitoring/anomalies/${anomalyId}/add_comment`, {
        message,
      }) as Promise<APIMonitoringAnomaly>;
  }

  assignAnomalyToUser(anomalyId: UUID, assignedToId: UUID | null | undefined) {
    return this.authenticated()
      .json()
      .stubbedWith({})
      .post(
        `${this.rootUrl}/monitoring/anomalies/${anomalyId}/assign_to_user`,
        {
          assigned_to_id: assignedToId || null,
        }
      ) as Promise<APIMonitoringAnomaly>;
  }

  resolveAnomaly(
    anomalyId: UUID,
    {
      resolutionType,
      resolutionDescription,
    }: {
      resolutionType: APIAnomalyResolutionType;
      resolutionDescription: string;
    }
  ) {
    return this.authenticated()
      .json()
      .stubbedWith({})
      .post(`${this.rootUrl}/monitoring/anomalies/${anomalyId}/resolve`, {
        resolution_type: resolutionType,
        resolution_description: resolutionDescription,
      }) as Promise<APIMonitoringAnomaly>;
  }

  getCompanyMatchProfiles() {
    return this.authenticated()
      .json()
      .stubbedWith(companyMatchProfiles)
      .get(`${this.rootUrl}/company/match_profiles`) as Promise<
      APICompanyMatchProfile[]
    >;
  }

  createMatchProfile(matchProfileFormData: MatchProfileOverrides) {
    return this.authenticated()
      .json()
      .stubbedWith(createMatchProfileResponse)
      .post(`${this.rootUrl}/company/match_profiles`, {
        countries: matchProfileFormData.countries.values,
        countries_override_type: matchProfileFormData.countries.managementType,
        languages: matchProfileFormData.languages.values,
        languages_override_type: matchProfileFormData.languages.managementType,
        currencies: matchProfileFormData.currencies.values,
        currencies_override_type:
          matchProfileFormData.currencies.managementType,
        industries: matchProfileFormData.highRiskIndustries,
      });
  }

  newCreateMatchProfile(matchProfile: MatchProfile) {
    return this.authenticated()
      .json()
      .stubbedWith(createMatchProfileResponse)
      .post(`${this.rootUrl}/company/match_profiles`, matchProfile);
  }

  getWebsiteStatus(website: string) {
    return this.authenticated()
      .json()
      .stubbedWith(websiteStatusResponse)
      .post(`${this.rootUrl}/website/status`, {
        website: website,
        request_timeout: 7.5,
      });
  }

  getAccountSearchStats(
    minDateTime?: string,
    maxDateTime?: string
  ): Promise<APIAccountSearchSummary> {
    const searchParams = new URLSearchParams();

    if (minDateTime) {
      searchParams.set("min_datetime", minDateTime);
    }

    if (maxDateTime) {
      searchParams.set("max_datetime", maxDateTime);
    }

    const search = searchParams.toString();

    return this.authenticated()
      .json()
      .stubbedWith(accountSearchStats)
      .get(`${this.rootUrl}/stats/searches${search ? `?${search}` : ""}`);
  }
}
