import { format, max, parseISO } from "date-fns";
import {
  APIAnomalyResolutionType,
  APIAnomalyStatus,
  APIAnomalyUser,
} from "../../../types/APIAnomaly";

export function formatDateTime(isoDateString?: string | null | undefined) {
  if (!isoDateString) return;

  try {
    return format(parseISO(isoDateString), "MMMM d, yyyy h:mm a");
  } catch (e) {
    console.error(e, isoDateString);
    return isoDateString;
  }
}

export function formatDate(isoDateString?: string | null | undefined) {
  if (!isoDateString) return;

  try {
    return format(parseISO(isoDateString), "MMMM d, yyyy");
  } catch (e) {
    console.error(e, isoDateString);
    return isoDateString;
  }
}

export const formatStatus = (status: APIAnomalyStatus): string => {
  switch (status) {
    case APIAnomalyStatus.OPEN:
      return "Open";
    case APIAnomalyStatus.ASSIGNED:
      return "Assigned";
    case APIAnomalyStatus.RESOLVED:
      return "Resolved";
  }
};

export const formatUser = (user: APIAnomalyUser) =>
  user.email || "a TrueBiz user";

export const formatResolutionType = (
  resolutionType: APIAnomalyResolutionType
): string => {
  switch (resolutionType) {
    case APIAnomalyResolutionType.CLEARED:
      return "cleared";
    case APIAnomalyResolutionType.NOTICE:
      return "notice sent";
    case APIAnomalyResolutionType.TERMINATED:
      return "merchant terminated";
    default:
      return "resolved";
  }
};

export const maxDate = (...dates: (string | null | undefined | Date)[]) => {
  const cleaned: (string | Date)[] = dates.filter((x) => !!x) as (
    | string
    | Date
  )[];
  try {
    return max(cleaned).toISOString();
  } catch (e) {
    return undefined;
  }
};
