import {
  Typography,
  Modal,
  Paper,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { contentCategories } from "./contentCategories";
import { MatchProfile } from "../types";

function CategorySummary({
  categoryKeys,
  allowedKeys,
}: {
  categoryKeys: string[];
  allowedKeys: string[];
}) {
  const blockedCount = categoryKeys.filter(
    (key: any) => !allowedKeys.includes(key)
  ).length;
  const color = blockedCount > 0 ? "red" : "green";

  return (
    <Typography
      variant="subtitle2"
      component="span"
      sx={{ mt: 1, color: color }}
    >
      {blockedCount} of {categoryKeys.length} blocked
    </Typography>
  );
}

export default function ContentModal({
  open,
  setOpenModal,
  matchProfile,
  updateMatchProfile,
}: {
  open: boolean;
  setOpenModal: (arg: string | null) => void;
  matchProfile: MatchProfile;
  updateMatchProfile: any;
}) {
  const toggleSubCat = (subcat: string) => {
    const newAllowed = [...matchProfile.allowedContentCategories];
    const index = newAllowed.indexOf(subcat);
    if (index !== -1) {
      newAllowed.splice(index, 1);
    } else {
      newAllowed.push(subcat);
    }
    newAllowed.sort();
    console.log(newAllowed);
    updateMatchProfile({ allowedContentCategories: newAllowed });
  };

  const blockCategory = (categoryKeys: string[]) => {
    const newAllowed = [...matchProfile.allowedContentCategories].filter(
      (key: string) => !categoryKeys.includes(key)
    );
    updateMatchProfile({ allowedContentCategories: [...new Set(newAllowed)] });
  };

  const enableCategory = (categoryKeys: string[]) => {
    const newBlocked = [...matchProfile.allowedContentCategories].concat(
      categoryKeys
    );
    updateMatchProfile({ allowedContentCategories: [...new Set(newBlocked)] });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenModal(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={2}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box marginBottom={4} position="relative">
            <IconButton
              onClick={() => setOpenModal(null)}
              sx={{ position: "absolute", top: -20, right: -15 }}
            >
              <CloseIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <Typography variant="h5" component="h3" sx={{ mb: 3 }}>
              Content Categories
            </Typography>

            {contentCategories.map((category) => (
              <Accordion key={category.name}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  key={category.name}
                >
                  <Typography variant="h6" component="span" sx={{ mr: 2 }}>
                    {category.name}
                  </Typography>
                  <CategorySummary
                    categoryKeys={category.subcats}
                    allowedKeys={matchProfile.allowedContentCategories}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => blockCategory(category.subcats)}
                    >
                      block all
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => enableCategory(category.subcats)}
                    >
                      enable all
                    </Button>
                  </Box>
                  {category.subcats.map((key: any) => (
                    <Box key={key}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              !matchProfile.allowedContentCategories.includes(
                                key
                              )
                            }
                            onChange={() => toggleSubCat(key)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={key}
                      />
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Paper>
      </Modal>
    </>
  );
}
