import { Fragment } from "react";
import {
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  Sell as TagIcon,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Grid, Typography, Chip } from "@mui/material";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIIndustry from "../../types/APIIndustry";

export default function Industry({
  industry,
  industryProperties,
}: {
  industry: APIIndustry | null;
  industryProperties: null | any;
}) {
  return (
    <>
      <Grid
        container
        spacing={2}
        marginTop={1}
        columnSpacing={{ md: 6 }}
        rowSpacing={{ md: 0 }}
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Attribute
            label="Primary industry"
            labelIcon={
              <Tooltip
                title={industryProperties?.primary_industry.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.primary_industry}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Primary NAICS code"
            labelIcon={
              <Tooltip
                title={industryProperties?.primary_naics.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.primary_naics}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Primary SIC code"
            labelIcon={
              <Tooltip
                title={industryProperties?.primary_sic.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.primary_sic}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Primary MCC code"
            labelIcon={
              <Tooltip
                title={industryProperties?.primary_mcc.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.primary_mcc &&
              `${industry.primary_mcc.code} (${industry.primary_mcc.description})`}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Additional NAICS codes"
            labelIcon={
              <Tooltip
                title={industryProperties?.additional_naics_codes.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.additional_naics_codes.join(", ")}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Additional SIC codes"
            labelIcon={
              <Tooltip
                title={industryProperties?.additional_sic_codes.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.additional_sic_codes.join(", ")}
          </Attribute>
        </Grid>

        <Grid item xs={4}>
          <Attribute
            label="Additional MCC codes"
            labelIcon={
              <Tooltip
                title={industryProperties?.additional_mcc_codes.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {industry?.additional_mcc_codes?.map((code, index, codes) => (
              <Fragment key={`${code}_${index}`}>
                <span>
                  {`${code.code} (${code.description})${
                    index === industry.additional_mcc_codes.length - 1
                      ? ""
                      : ", "
                  }`}
                </span>
                {index !== codes.length - 1 && <br />}
              </Fragment>
            ))}
          </Attribute>
        </Grid>
      </Grid>

      {industry?.tags && industry.tags.length > 0 && (
        <>
          <Typography
            fontSize={14}
            fontWeight={600}
            color={grey[600]}
            marginTop={2}
            marginBottom={1}
          >
            Associated tags
            <Tooltip
              title={industryProperties?.tags.description}
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          </Typography>

          {industry?.tags.map((tag) => (
            <Chip
              key={tag}
              icon={<TagIcon fontSize="small" color="disabled" />}
              label={tag}
              variant="outlined"
              sx={{ marginRight: 1, marginBottom: 1.5 }}
            />
          ))}
        </>
      )}
    </>
  );
}
