/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useInspectIssueIds = () => {
  const [searchParams] = useSearchParams();
  const issueId = searchParams.get("issueId");
  const monitorDomain = searchParams.get("monitorDomain");
  return useMemo(() => ({ issueId, monitorDomain }), [issueId, monitorDomain]);
};

export const useCloseIssueDetailModal = () => {
  const [_, setSearchParams] = useSearchParams();
  return useCallback(() => {
    setSearchParams((params) => {
      params.delete("issueId");
      params.delete("monitorDomain");

      return params;
    });
  }, [setSearchParams]);
};

export const useOpenIssueDetailModal = () => {
  const [_, setSearchParams] = useSearchParams();
  const close = useCloseIssueDetailModal();

  return useCallback(
    (issueId: UUID, monitorDomain?: string | null | undefined) => {
      setSearchParams((params) => {
        params.set("issueId", issueId);
        if (monitorDomain) {
          params.set("monitorDomain", monitorDomain);
        }

        return params;
      });

      return close;
    },
    [setSearchParams, close]
  );
};

export const useCloseMonitorDetailModal = useCloseIssueDetailModal;

export const useOpenMonitorDetailModal = () => {
  const [_, setSearchParams] = useSearchParams();
  const close = useCloseMonitorDetailModal();

  return useCallback(
    (monitorDomain: string) => {
      setSearchParams((params) => {
        params.set("monitorDomain", monitorDomain);
        return params;
      });

      return close;
    },
    [setSearchParams, close]
  );
};
