import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import DetailsModal, { useOpenMonitorDetailModal } from "./DetailModal";

import MonitorPortfolio from "./PortfolioViews/MonitorPortfolio";
import { useTrueBizApi } from "../../api";

export interface Props {}

export default function MonitoringV3() {
  const queryClient = useQueryClient();
  const api = useTrueBizApi();

  const openModal = useOpenMonitorDetailModal();
  const [searchParams] = useSearchParams();

  const onOpenMonitorDetail = useCallback(
    (monitorDomain: string) => {
      queryClient.prefetchQuery({
        queryKey: ["getMonitor", monitorDomain],
        queryFn: () => api.getMonitoringEnrollment(monitorDomain),
      });

      setTimeout(() => openModal(monitorDomain), 200);
    },
    [openModal, queryClient, api]
  );

  return (
    <>
      <Routes>
        <Route
          path="portfolio"
          element={
            <MonitorPortfolio
              onOpenMonitorDetail={onOpenMonitorDetail}
              tableTitle={
                <h1
                  style={{
                    padding: 0,
                    margin: 0,
                    flex: 1,
                  }}
                >
                  Monitoring
                </h1>
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={{ pathname: "portfolio", search: searchParams.toString() }}
            />
          }
        />
      </Routes>
      <DetailsModal />
    </>
  );
}
