import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TrustRecommendation from "./TrustRecommendation";
import APIRiskIndicators from "../../../types/APIRiskIndicators";
import APIRecommendation from "../../../types/APIRecommendation";
import APIRisk from "../../../types/APIRisk";
import { BusinessAttributeSection } from "../../../types/BusinessAttributeSection";
import { humanizeString } from "../../../utilities/formatting";

type RiskCategory = string;
type RiskSubCategory = string;
type RiskName = string;

const SPECIFIC_RISK_MAP: {
  [v: RiskCategory]: { [v: RiskName]: BusinessAttributeSection | null };
} = {
  website: {
    is_email_domain_same: BusinessAttributeSection.ContactInfo,
    is_known_top_domain: BusinessAttributeSection.WebsiteTraffic,
    is_primary_phone_number_inactive: BusinessAttributeSection.ContactInfo,
    is_primary_phone_number_invalid: BusinessAttributeSection.ContactInfo,
    is_primary_phone_number_linked_to_company:
      BusinessAttributeSection.ContactInfo,
    is_primary_phone_number_missing_owner: BusinessAttributeSection.ContactInfo,
    is_primary_phone_number_type_suspicious:
      BusinessAttributeSection.ContactInfo,
    is_privacy_policy_suspicious:
      BusinessAttributeSection.WebsitePolicyAnalysis,
    is_website_content_duplicated: BusinessAttributeSection.DuplicateWebsites,
    has_all_invalid_emails: BusinessAttributeSection.ContactInfo,
    has_all_valid_phone_numbers: BusinessAttributeSection.ContactInfo,
    has_email_domain_not_matched: BusinessAttributeSection.ContactInfo,
    has_redirected_domain: BusinessAttributeSection.WebsiteContent,
    has_some_invalid_emails: BusinessAttributeSection.ContactInfo,
    has_policy_no_refunds: BusinessAttributeSection.WebsitePolicyAnalysis,
    has_policy_not_timely_refunds:
      BusinessAttributeSection.WebsitePolicyAnalysis,
  },
  reputation: {
    has_closed_indicators: BusinessAttributeSection.Reviews,
  },
};

const CATEGORY_SECTION_MAP: {
  [v: RiskCategory]: { [v: RiskSubCategory]: BusinessAttributeSection | null };
} = {
  location: {
    hosting_provider: BusinessAttributeSection.LocationData,
    premise: BusinessAttributeSection.LocationData,
    registrar: BusinessAttributeSection.LocationData,
    traffic: BusinessAttributeSection.WebsiteTraffic,
  },
  profile: {
    general: BusinessAttributeSection.Profile,
    industry: BusinessAttributeSection.Industry,
    metrics: BusinessAttributeSection.Profile,
    connected_entities: BusinessAttributeSection.ConnectedEntities,
  },
  reputation: {
    fraud: BusinessAttributeSection.Profile,
    registration: BusinessAttributeSection.Profile,
    reviews: BusinessAttributeSection.Reviews,
    social_media: BusinessAttributeSection.SocialMedia,
    news_media: BusinessAttributeSection.NewsArticles,
    web_presence: BusinessAttributeSection.WebsiteTraffic,
  },
  website: {
    behavior: BusinessAttributeSection.WebserverAnalysis,
    configuration: BusinessAttributeSection.WebserverAnalysis,
    content: BusinessAttributeSection.WebsiteContent,
    registration: BusinessAttributeSection.DomainAnalysis,
    traffic: BusinessAttributeSection.WebsiteTraffic,
  },
};

function Finding({
  risk,
  titleColor,
  icon,
  setHighlightedSection,
}: {
  risk: APIRisk;
  icon: ReactNode;
  titleColor: string;
  setHighlightedSection: (section: BusinessAttributeSection | null) => void;
}) {
  return (
    <ListItem
      divider
      disablePadding
      secondaryAction={
        <Typography
          color="grey.A700"
          fontSize="small"
          textTransform="uppercase"
          sx={{ maxWidth: "140px" }}
        >
          {humanizeString(risk.subcategory)}
        </Typography>
      }
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => {
          setHighlightedSection(
            (SPECIFIC_RISK_MAP[risk.category] || {})[risk.name] ||
              (CATEGORY_SECTION_MAP[risk.category] || {})[risk.subcategory] ||
              null
          );
        }}
      >
        <ListItemIcon sx={{ minWidth: 40, paddingLeft: 0.5 }}>
          {icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          sx={{ paddingRight: "100px" }}
          primary={
            <Typography color={titleColor}>{risk.display_name}</Typography>
          }
          secondary={
            <Typography color="grey.A700" fontSize="small">
              {risk.description}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

function ValidityFindings({
  risks,
  setHighlightedSection,
}: {
  risks: APIRisk[];
  setHighlightedSection: (section: BusinessAttributeSection | null) => void;
}) {
  return (
    <>
      {risks.map((risk) => (
        <Finding
          setHighlightedSection={setHighlightedSection}
          key={JSON.stringify(risk)}
          risk={risk}
          icon={<CheckCircleOutlinedIcon color="success" />}
          titleColor="success.main"
        />
      ))}
    </>
  );
}

function RiskFindings({
  risks,
  setHighlightedSection,
}: {
  risks: APIRisk[];
  setHighlightedSection: (section: BusinessAttributeSection | null) => void;
}) {
  return (
    <>
      {risks.map((risk) => (
        <Finding
          setHighlightedSection={setHighlightedSection}
          key={JSON.stringify(risk)}
          risk={risk}
          icon={<CancelOutlinedIcon color="error" />}
          titleColor="error.main"
        />
      ))}
    </>
  );
}

function NoticeFindings({
  risks,
  setHighlightedSection,
}: {
  risks: APIRisk[];
  setHighlightedSection: (section: BusinessAttributeSection | null) => void;
}) {
  return (
    <>
      {risks.map((risk) => (
        <Finding
          setHighlightedSection={setHighlightedSection}
          key={JSON.stringify(risk)}
          risk={risk}
          icon={<InfoOutlinedIcon color="warning" />}
          titleColor="warning.main"
        />
      ))}
    </>
  );
}

export default function Findings({
  recommendation,
  setHighlightedSection,
  risks,
}: {
  recommendation: APIRecommendation | null;
  setHighlightedSection: (section: BusinessAttributeSection | null) => void;
  risks: APIRiskIndicators;
}) {
  const pass = recommendation
    ? recommendation.decision.toLowerCase() === "pass"
    : null;

  let findings;
  let notices;
  let hasNotices = false;
  let hasFindings = false;

  if (pass === true) {
    findings = (
      <ValidityFindings
        setHighlightedSection={setHighlightedSection}
        risks={risks.validity}
      />
    );
    hasFindings = risks.validity.length > 0;

    notices = (
      <>
        <RiskFindings
          risks={risks.risk}
          setHighlightedSection={setHighlightedSection}
        />
        <NoticeFindings
          risks={risks.notice}
          setHighlightedSection={setHighlightedSection}
        />
      </>
    );

    hasNotices = risks.risk.length + risks.notice.length > 0;
  } else if (pass === false) {
    findings = (
      <>
        <RiskFindings
          risks={risks.risk}
          setHighlightedSection={setHighlightedSection}
        />
        <NoticeFindings
          risks={risks.notice}
          setHighlightedSection={setHighlightedSection}
        />
      </>
    );
    hasFindings = risks.risk.length + risks.notice.length > 0;

    notices = (
      <ValidityFindings
        risks={risks.validity}
        setHighlightedSection={setHighlightedSection}
      />
    );
    hasNotices = risks.validity.length > 0;
  } else {
    findings = (
      <>
        <ValidityFindings
          risks={risks.validity}
          setHighlightedSection={setHighlightedSection}
        />
        <RiskFindings
          risks={risks.risk}
          setHighlightedSection={setHighlightedSection}
        />
      </>
    );
    hasFindings = risks.validity.length + risks.risk.length > 0;

    notices = (
      <NoticeFindings
        risks={risks.notice}
        setHighlightedSection={setHighlightedSection}
      />
    );
    hasNotices = risks.notice.length > 0;
  }

  return (
    <>
      <TrustRecommendation pass={pass} />

      {hasFindings && (
        <Box marginBottom={3}>
          <Typography variant="h6" component="h3" marginBottom={1}>
            Findings
          </Typography>

          <List sx={{ py: 0, boxShadow: 2, borderRadius: 1 }}>{findings}</List>
        </Box>
      )}

      {hasNotices && (
        <Box>
          <Typography variant="h6" component="h3" marginBottom={1}>
            Informational Notices
          </Typography>

          <List sx={{ py: 0, boxShadow: 2, borderRadius: 1 }}>{notices}</List>
        </Box>
      )}
    </>
  );
}
