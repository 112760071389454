import { Box, Paper } from "@mui/material";

import useAdminSettings, { DEFAULT_SETTINGS } from "../utilities/adminSettings";

export default function Admin() {
  const [settings, setSettings] = useAdminSettings();

  return (
    <Paper elevation={2} sx={{ mt: 3, p: 3 }}>
      {(Object.keys(DEFAULT_SETTINGS) as (keyof typeof DEFAULT_SETTINGS)[]).map(
        (key) => (
          <Box sx={{ p: 3 }} key={key}>
            <label>
              <input
                type="checkbox"
                checked={settings[key]}
                onChange={() =>
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    [key]: !settings[key],
                  }))
                }
              />
              {key}
            </label>
          </Box>
        )
      )}
    </Paper>
  );
}
