import { Box, Grid, Typography, Divider } from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";

import BusinessConnections from "./BusinessConnections";
import BooleanAttribute from "./../BooleanAttribute";
import Attribute from "./../Attribute";
import Tooltip from "../../Tooltip";
import APIPhone from "../../../types/APIPhone";
import { DataSource } from "../../../types/APIApplication";
import { formatPhoneNumber } from "../../../utilities/formatting";

export default function SubmittedPhone({
  phone,
  phoneBusinessConnections,
}: {
  phone: APIPhone | null | undefined;
  phoneBusinessConnections: DataSource[] | null | undefined;
}) {
  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ height: 55 }}>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18 }}
          variant="h6"
          component="span"
          marginBottom={1}
        >
          Phone
        </Typography>

        <Typography sx={{ ml: 2 }} variant="subtitle1" component="span">
          {phone?.number && formatPhoneNumber(phone.number)}
        </Typography>
      </Box>
      <Box sx={{ pr: 3, mb: 3 }}>
        <Divider />
      </Box>

      {phone ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BusinessConnections sources={phoneBusinessConnections} />
          </Grid>
          <Grid item xs={12}>
            <BooleanAttribute
              label="Valid?"
              labelIcon={
                <Tooltip
                  title="Is the phone number a proper format and potentially functional?"
                  placement="right"
                  arrow
                >
                  <HelpOutlineOutlinedIcon
                    fontSize="inherit"
                    sx={{ ml: 0.25 }}
                  />
                </Tooltip>
              }
              // only show validity signals, no validation failures
              value={phone.is_valid}
              trueIsPositive
              failureText="Unknown"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Attribute label="Line type">{phone.line_type}</Attribute>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Attribute label="Country">{phone.country_name}</Attribute>
          </Grid>
        </Grid>
      ) : (
        <Box>Not sent</Box>
      )}
    </Box>
  );
}
