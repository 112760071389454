import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { Box, Grid, Link, Typography } from "@mui/material";
import {
  blue,
  grey,
  lightGreen,
  orange,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartjsTooltip,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import APIWebsiteTraffic from "../../types/APIWebsiteTraffic";

ChartJS.register(
  ChartjsTooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  Colors
);

function NoTraffic() {
  const theme = useTheme();
  return (
    <Typography variant="caption" color={theme.palette.grey[500]}>
      No traffic
    </Typography>
  );
}

const singlePercentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const compactNumberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact" as const,
  compactDisplay: "short" as const,
});

const sourcesPieOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right" as const,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context: { label: string }) {
          return context.label;
        },
      },
    },
  },
};

const historyLineOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context: { raw: unknown; label: string }) {
          const num =
            typeof context.raw === "number"
              ? context.raw.toLocaleString("en-US")
              : context.raw;
          return `${context.label}: ${num}`;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        callback: function (value: string | number) {
          const num = typeof value === "string" ? parseInt(value) : value;
          return compactNumberFormatter.format(num);
        },
      },
    },
  },
};

const countriesBarOptions = {
  maintainAspectRatio: false,
  indexAxis: "y" as const,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context: { label: string }) {
          return context.label;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback: function (value: string | number) {
          const num = typeof value === "string" ? parseInt(value) : value;
          return new Intl.NumberFormat("en-US", {
            style: "percent" as const,
          }).format(num);
        },
      },
    },
  },
};

function AttributesContainer({
  websiteTraffic,
  websiteTrafficProperties,
  itemSizes,
}: {
  websiteTraffic: APIWebsiteTraffic | null | undefined;
  websiteTrafficProperties: any | null | undefined;
  itemSizes: number[];
}) {
  return (
    <Grid
      container
      spacing={1}
      marginTop={0}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 0 }}
    >
      <Grid
        item
        xs={itemSizes[0]}
        sm={itemSizes[1]}
        md={itemSizes[2]}
        lg={itemSizes[3]}
        marginBottom={2}
      >
        <Attribute
          label="Visits per Month"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.visits_per_month.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {!websiteTraffic && <NoTraffic />}
          {websiteTraffic?.visits_per_month &&
            websiteTraffic.visits_per_month.toLocaleString("en-US")}
        </Attribute>
      </Grid>

      <Grid
        item
        xs={itemSizes[0]}
        sm={itemSizes[1]}
        md={itemSizes[2]}
        lg={itemSizes[3]}
        marginBottom={2}
      >
        <Attribute
          label="Time on Site"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.time_on_site.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {!websiteTraffic && <NoTraffic />}
          {websiteTraffic?.time_on_site &&
            websiteTraffic.time_on_site.toFixed(0) + "s"}
        </Attribute>
      </Grid>

      <Grid
        item
        xs={itemSizes[0]}
        sm={itemSizes[1]}
        md={itemSizes[2]}
        lg={itemSizes[3]}
        marginBottom={2}
      >
        <Attribute
          label="Pages per Visit"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.pages_per_visit.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {!websiteTraffic && <NoTraffic />}
          {websiteTraffic?.pages_per_visit?.toFixed(1)}
        </Attribute>
      </Grid>

      <Grid
        item
        xs={itemSizes[0]}
        sm={itemSizes[1]}
        md={itemSizes[2]}
        lg={itemSizes[3]}
        marginBottom={2}
      >
        <Attribute
          label="Bounce Rate"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.bounce_rate.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {!websiteTraffic && <NoTraffic />}
          {websiteTraffic?.bounce_rate &&
            new Intl.NumberFormat("en-US", { style: "percent" }).format(
              websiteTraffic.bounce_rate
            )}
        </Attribute>
      </Grid>
      <Grid
        item
        xs={itemSizes[0]}
        sm={itemSizes[1]}
        md={itemSizes[2]}
        lg={itemSizes[3]}
        marginBottom={2}
      >
        <Attribute
          label="Google Results Count"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.google_results_count.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {!websiteTraffic && <NoTraffic />}
          {websiteTraffic?.google_results_count &&
            websiteTraffic.google_results_count.toLocaleString("en-US")}
        </Attribute>
      </Grid>

      <Grid
        item
        xs={itemSizes[0]}
        sm={itemSizes[1]}
        md={itemSizes[2]}
        lg={itemSizes[3]}
        marginBottom={2}
      >
        <Attribute
          label="Paid Search Visits"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.paid_search_visits.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          {!websiteTraffic && <NoTraffic />}
          {websiteTraffic?.paid_search_visits &&
            websiteTraffic.paid_search_visits.toLocaleString("en-US")}
        </Attribute>
      </Grid>

      {(!websiteTraffic || websiteTraffic?.top_referring_sites) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={Math.max(itemSizes[3], 8)}
          lg={Math.max(itemSizes[3], 8)}
          marginBottom={2}
        >
          <Attribute
            label="Top referring websites"
            labelIcon={
              <Tooltip
                title={
                  websiteTrafficProperties &&
                  websiteTrafficProperties.top_referring_sites.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
          >
            {(websiteTraffic?.top_referring_sites || []).map((topRef) => {
              return (
                <Typography key={topRef.url}>
                  {Math.round(topRef.traffic_share * 100)}%<span> - </span>
                  <Link
                    href={topRef.url}
                    sx={{ ml: 0.25 }}
                    target="_blank"
                    rel="noopener"
                  >
                    {topRef.url}
                  </Link>
                </Typography>
              );
            })}
            {!websiteTraffic && <NoTraffic />}
          </Attribute>
        </Grid>
      )}
    </Grid>
  );
}

export default function WebsiteTraffic({
  websiteTraffic,
  websiteTrafficProperties,
}: {
  websiteTraffic: APIWebsiteTraffic | null | undefined;
  websiteTrafficProperties: any | null | undefined;
}) {
  const theme = useTheme();

  if (websiteTraffic && !websiteTraffic.visits_per_month)
    return (
      <Grid item xs={12} lg={4}>
        <AttributesContainer
          websiteTraffic={websiteTraffic}
          websiteTrafficProperties={websiteTrafficProperties}
          itemSizes={[12, 6, 3, 3]}
        />
      </Grid>
    );

  const topCountries = websiteTraffic?.top_country_shares
    ? websiteTraffic.top_country_shares.slice(0, 6)
    : [];

  const countriesBarData =
    topCountries.length > 0
      ? {
          labels: topCountries.map(({ name, abbr, value }) => {
            const label = `${
              name.length > 20 ? abbr : name
            }: ${singlePercentFormatter.format(value)}`;

            return label;
          }),
          datasets: [
            {
              data: topCountries.map((countryShare) => countryShare.value),
              backgroundColor: topCountries.map(
                (_, i) =>
                  [teal[800], teal[600], teal[400], teal[200], teal[50]][i]
              ),
            },
          ],
        }
      : undefined;

  const sourcesPieData = websiteTraffic?.source_shares && {
    labels: Object.entries(websiteTraffic.source_shares).map(
      ([key, value], i) => `${key}: ${singlePercentFormatter.format(value)}`
    ),
    datasets: [
      {
        data: Object.values(websiteTraffic.source_shares),
        backgroundColor: [
          red[800],
          yellow[800],
          purple[800],
          blue[800],
          lightGreen[800],
          orange[800],
        ],
      },
    ],
  };

  const historyMonths = websiteTraffic?.historical_visits_per_month
    ? websiteTraffic.historical_visits_per_month.slice(0, 7)
    : [];

  const historyLineData =
    historyMonths.length > 2
      ? {
          labels: historyMonths.map((monthVisits) => {
            try {
              const monthNumStr = new Date(monthVisits.month.toString());
              const monthAbbr = Intl.DateTimeFormat("en", {
                month: "short",
              }).format(monthNumStr);
              const yearTwo = monthVisits.year.toString().substring(2);
              return `${monthAbbr} ${yearTwo}`;
            } catch (e) {
              console.error(e);
              return `${monthVisits.month} ${monthVisits.year}`;
            }
          }),
          datasets: [
            {
              data: historyMonths.map((monthVisits) => monthVisits.visits),
              borderColor: theme.palette.primary.main,
              tension: 0.2,
            },
          ],
        }
      : undefined;

  return (
    <Grid
      container
      spacing={2}
      marginTop={0}
      columnSpacing={{ md: 6 }}
      rowSpacing={{ md: 0 }}
    >
      <Grid item xs={12} lg={8}>
        {(!websiteTraffic || historyLineData) && (
          <Grid item xs={12}>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={grey[600]}
              marginTop={0}
              marginBottom={0}
            >
              Monthly Visits History{!websiteTraffic && " (No traffic found)"}
              <Tooltip
                title={
                  websiteTrafficProperties &&
                  websiteTrafficProperties.historical_visits_per_month
                    .description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            </Typography>
            <Box position="relative" height="150px" marginBottom={3}>
              {historyLineData && (
                <Line data={historyLineData} options={historyLineOptions} />
              )}
            </Box>
          </Grid>
        )}

        {(!websiteTraffic || countriesBarData) && (
          <Grid item xs={12}>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={grey[600]}
              marginTop={0}
              marginBottom={0}
            >
              Top Countries Share {!websiteTraffic && "(No traffic found)"}
              <Tooltip
                title={
                  websiteTrafficProperties &&
                  websiteTrafficProperties.top_country_shares.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            </Typography>
            <Box position="relative" height="150px" marginBottom={3}>
              <Bar
                data={
                  countriesBarData || {
                    labels: [],
                    datasets: [
                      {
                        data: [],
                        borderColor: theme.palette.primary.main,
                      },
                    ],
                  }
                }
                options={countriesBarOptions}
              />
            </Box>
          </Grid>
        )}

        {sourcesPieData && (
          <Grid item xs={12}>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={grey[600]}
              marginTop={0}
              marginBottom={0}
            >
              Traffic Sources
              <Tooltip
                title={
                  websiteTrafficProperties &&
                  websiteTrafficProperties.source_shares.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            </Typography>
            <Box position="relative" height="150px" marginBottom={3}>
              <Pie data={sourcesPieData} options={sourcesPieOptions} />
            </Box>
          </Grid>
        )}
        {!websiteTraffic && (
          <Grid item xs={12}>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={grey[600]}
              marginTop={0}
              marginBottom={0}
            >
              No Traffic Sources Found
              <Tooltip
                title={
                  websiteTrafficProperties &&
                  websiteTrafficProperties.source_shares.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} lg={2}>
        <AttributesContainer
          websiteTraffic={websiteTraffic}
          websiteTrafficProperties={websiteTrafficProperties}
          itemSizes={[12, 6, 6, 12]}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Attribute
          label="Top Organic Keywords"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.top_keywords.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          <Box sx={{ mt: 1, mb: 5 }}>
            {(websiteTraffic?.top_keywords?.organic || []).map((keyword) => (
              <Box key={keyword} sx={{ mt: 0.5 }}>
                <Typography style={{ wordWrap: "break-word" }}>
                  "{keyword}"
                </Typography>
              </Box>
            ))}
            {!websiteTraffic && <NoTraffic />}
          </Box>
        </Attribute>
        <Attribute
          label="Top Paid Keywords"
          labelIcon={
            <Tooltip
              title={
                websiteTrafficProperties &&
                websiteTrafficProperties.top_keywords.description
              }
              placement="right"
              arrow
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
            </Tooltip>
          }
        >
          <Box sx={{ mt: 1 }}>
            {(websiteTraffic?.top_keywords?.paid || []).map((keyword) => (
              <Box key={keyword} sx={{ mt: 0.5 }}>
                <Typography style={{ wordWrap: "break-word" }} key={keyword}>
                  "{keyword}"
                </Typography>
              </Box>
            ))}
            {!websiteTraffic && <NoTraffic />}
          </Box>
        </Attribute>
      </Grid>
    </Grid>
  );
}
