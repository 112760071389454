import { Tooltip, useTheme } from "@mui/material";
import {
  ReactNode,
  ReactElement,
  cloneElement,
  isValidElement,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";

export interface Props {
  children: ReactNode;
  getTitle?: (children: ReactNode) => ReactNode;
  tooltipEnabled?: boolean;
  tooltipDelayMs?: number;
  tooltipOffsetXPx?: number;
  tooltipOffsetYPx?: number;
}

// if we don't do this we'll end up with the dreaded <foo>
// cannot appear as a descendent of <bar> warning in development
export const getTitleByUnwrappingChildren = (children: ReactNode) => {
  if (!isValidElement(children)) return children;
  if (!isValidElement(children.props?.children)) return children;

  const unwrapped = children.props.children as ReactElement;
  return cloneElement(unwrapped, unwrapped.props);
};

export default function OverflowTooltip({
  children,
  getTitle = getTitleByUnwrappingChildren,
  tooltipEnabled = true,
  tooltipDelayMs = 750,
  tooltipOffsetXPx = -4,
  tooltipOffsetYPx = -48,
}: Props) {
  const theme = useTheme();
  const title = useMemo(() => getTitle(children), [children, getTitle]);

  const contentRef = useRef<HTMLElement | null>(null);

  const [showTitle, setShowTitle] = useState(false);

  const contents = useMemo(() => {
    if (!isValidElement(children)) return children;

    return cloneElement(children, {
      ...(children?.props || {}),
      ref: mergeRefs([contentRef, (children as any)?.ref]),
    });
  }, [children]);

  if (!tooltipEnabled) return children as ReactElement;

  return (
    <Tooltip
      placement="bottom-start"
      disableInteractive
      enterDelay={tooltipDelayMs}
      enterNextDelay={tooltipDelayMs}
      title={title}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [tooltipOffsetXPx, tooltipOffsetYPx],
              },
            },
          ],
        },
      }}
      onOpen={(e) => {
        if (!contentRef.current) return;

        const scrollWidth = contentRef.current.scrollWidth;
        const clientWidth = contentRef.current.clientWidth;

        const scrollHeight = contentRef.current.scrollHeight;
        const clientHeight = contentRef.current.clientHeight;

        const isOverflowed =
          scrollWidth > clientWidth || scrollHeight > clientHeight;

        setShowTitle(isOverflowed);
      }}
      onClose={() => {
        setShowTitle(false);
      }}
      open={showTitle}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 14,
          },
        },
      }}
    >
      {/* Important! Don't wrap this in a Fragment or whatever, or it breaks the mui tooltip magic */}
      {contents as any}
    </Tooltip>
  );
}
