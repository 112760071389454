import { Fragment, useState, ReactNode } from "react";
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getCountryNameByAlpha2 } from "country-locale-map";

import IndustryModal from "./IndustryModal";
import LocationModal from "./LocationModal";
import ContentModal from "./ContentModal";
import TrafficModal from "./TrafficModal";
import SentimentModal from "./SentimentModal";
import PoliciesModal from "./PoliciesModal";
import Confirmation from "./Confirmation";
import { mccData } from "./mccData";
import { MatchProfile } from "../types";
import { useTrueBizApi } from "../../../api";

const defaultMatchProfile: MatchProfile = {
  enableAdverseMedia: true,
  enableNagativeBusinessReviews: true,
  enablePaidSearchRate: true,
  enableTimeOnSite: true,
  enableCountryTraffic: true,
  enableReferringSite: true,
  enableBounceRate: true,
  enablePolicies: true,
  enableReviews: true,
  allowedCountryCodes: ["US"],
  blockedMccCodes: [],
  allowedContentCategories: [],
};

function PreviewCard({
  title,
  children,
  clickHandler,
}: {
  title: string;
  children: ReactNode;
  clickHandler: () => void;
}) {
  return (
    <Card
      onClick={clickHandler}
      elevation={2}
      sx={{ height: 180, maxWidth: 550 }}
    >
      <CardActionArea sx={{ height: "100%", p: 2, position: "relative" }}>
        <Typography variant="h5" sx={{ position: "absolute", top: 10 }}>
          {title}
        </Typography>
        <Divider sx={{ position: "absolute", top: 45, left: 0, right: 60 }} />
        <Box sx={{ position: "absolute", top: 60 }}>{children}</Box>
        <Typography
          variant="body2"
          sx={{ position: "absolute", bottom: 10, right: 10 }}
        >
          Click to Edit
        </Typography>
      </CardActionArea>
    </Card>
  );
}

export default function MatchProfileForm() {
  const api = useTrueBizApi();
  const [matchProfile, setMatchProfile] =
    useState<MatchProfile>(defaultMatchProfile);
  const [openModal, setOpenModal] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const updateMatchProfile = (update: any) => {
    setMatchProfile({ ...matchProfile, ...update });
  };

  const handleSaveProfile = async () => {
    try {
      setSubmitted(true);
      setError(false);
      setLoading(true);
      await api.newCreateMatchProfile(matchProfile);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const allowedCountryNames = matchProfile.allowedCountryCodes
    .slice(0, 7)
    .map((cc) => getCountryNameByAlpha2(cc));
  const remainingAllowedCount =
    matchProfile.allowedCountryCodes.length - allowedCountryNames.length;

  const blockedIndustries = mccData
    .filter((row: any) => matchProfile.blockedMccCodes.includes(row.code))
    .map((row: any) => row.description);
  const shownBlockedIndustries = blockedIndustries.slice(0, 4);
  const remainingBlockedIndustries =
    blockedIndustries.length - shownBlockedIndustries.length;

  const trafficRisks = [
    matchProfile.enablePaidSearchRate,
    matchProfile.enableTimeOnSite,
    matchProfile.enableCountryTraffic,
    matchProfile.enableReferringSite,
    matchProfile.enableBounceRate,
  ];

  const sentimentRisks = [
    matchProfile.enableReviews,
    matchProfile.enableAdverseMedia,
  ];

  const policiesRisks = [matchProfile.enablePolicies];

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box sx={{ width: "100%", maxWidth: 1150, position: "relative", pt: 5 }}>
        {submitted && <Confirmation loading={loading} error={error} />}
        <LoadingButton
          variant="contained"
          size="large"
          onClick={handleSaveProfile}
          disabled={submitted}
          loading={loading}
          sx={{ position: "absolute", top: -55, right: 0 }}
        >
          SAVE
        </LoadingButton>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <PreviewCard
              clickHandler={() => setOpenModal("industry")}
              title="Industry Classification"
            >
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ fontWeight: 600, mr: 2 }}
              >
                Blocked Industries:
              </Typography>
              {blockedIndustries.length ? (
                <>
                  {shownBlockedIndustries.map(
                    (blockedIndustry: string, index: number) => (
                      <Fragment key={blockedIndustry}>
                        {index > 0 && ", "}
                        <Typography variant="subtitle1" component="span">
                          {blockedIndustry}
                        </Typography>
                      </Fragment>
                    )
                  )}
                  {remainingBlockedIndustries > 0 && (
                    <Typography variant="subtitle1" component="span">
                      ...{remainingBlockedIndustries} more
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="subtitle1" component="span">
                  None
                </Typography>
              )}
            </PreviewCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewCard
              clickHandler={() => setOpenModal("content")}
              title="Content"
            >
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ fontWeight: 600, mr: 2 }}
              >
                Allowed Content Categories:
              </Typography>
              {matchProfile.allowedContentCategories.length ? (
                <>
                  {matchProfile.allowedContentCategories.map(
                    (contentSubCat: any, index: number) => (
                      <Fragment key={contentSubCat}>
                        {index > 0 && ", "}
                        <Typography variant="subtitle1" component="span">
                          {contentSubCat}
                        </Typography>
                      </Fragment>
                    )
                  )}
                </>
              ) : (
                <Typography variant="subtitle1" component="span">
                  None
                </Typography>
              )}
            </PreviewCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewCard
              clickHandler={() => setOpenModal("location")}
              title="Location"
            >
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ fontWeight: 600, mr: 2 }}
              >
                Allowed Countries:
              </Typography>
              {allowedCountryNames ? (
                <>
                  {allowedCountryNames.map(
                    (countryName: any, index: number) => (
                      <Fragment key={countryName}>
                        {index > 0 && ", "}
                        <Typography variant="subtitle1" component="span">
                          {countryName}
                        </Typography>
                      </Fragment>
                    )
                  )}
                  {remainingAllowedCount > 0 && (
                    <Typography variant="subtitle1" component="span">
                      ... {remainingAllowedCount} more
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant="subtitle1" component="span">
                  None
                </Typography>
              )}
            </PreviewCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewCard
              clickHandler={() => setOpenModal("traffic")}
              title="Traffic"
            >
              <Typography variant="subtitle1" component="span">
                <strong>{trafficRisks.filter(Boolean).length}</strong> out of{" "}
                {trafficRisks.length} risks enabled
              </Typography>
            </PreviewCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewCard
              clickHandler={() => setOpenModal("sentiment")}
              title="Sentiment"
            >
              <Typography variant="subtitle1" component="span">
                <strong>{sentimentRisks.filter(Boolean).length}</strong> out of{" "}
                {sentimentRisks.length} risks enabled
              </Typography>
            </PreviewCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <PreviewCard
              clickHandler={() => setOpenModal("policies")}
              title="Policies"
            >
              <Typography variant="subtitle1" component="span">
                <strong>{policiesRisks.filter(Boolean).length}</strong> out of{" "}
                {policiesRisks.length} risks enabled
              </Typography>
            </PreviewCard>
          </Grid>
        </Grid>
      </Box>
      <LocationModal
        open={openModal === "location"}
        setOpenModal={setOpenModal}
        matchProfile={matchProfile}
        updateMatchProfile={updateMatchProfile}
      />
      <IndustryModal
        open={openModal === "industry"}
        setOpenModal={setOpenModal}
        matchProfile={matchProfile}
        updateMatchProfile={updateMatchProfile}
      />
      <ContentModal
        open={openModal === "content"}
        setOpenModal={setOpenModal}
        matchProfile={matchProfile}
        updateMatchProfile={updateMatchProfile}
      />
      <TrafficModal
        open={openModal === "traffic"}
        setOpenModal={setOpenModal}
        matchProfile={matchProfile}
        updateMatchProfile={updateMatchProfile}
      />
      <SentimentModal
        open={openModal === "sentiment"}
        setOpenModal={setOpenModal}
        matchProfile={matchProfile}
        updateMatchProfile={updateMatchProfile}
      />
      <PoliciesModal
        open={openModal === "policies"}
        setOpenModal={setOpenModal}
        matchProfile={matchProfile}
        updateMatchProfile={updateMatchProfile}
      />
    </Box>
  );
}
