import { useQuery } from "@tanstack/react-query";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTrueBizApi } from "../../../api";
import MonitorTimeline from "../MonitorTimeline";

export interface Props {
  issueId: UUID;
  monitorDomain?: string | null | undefined;
}

export default function IssueDetails({ issueId, monitorDomain }: Props) {
  const api = useTrueBizApi();

  const issueQuery = useQuery({
    queryKey: ["getNotification", issueId],
    queryFn: () => api.getAnomaly(issueId!),
    enabled: monitorDomain == null, // look up only if we're missing it
  });

  const loading =
    monitorDomain == null ? issueQuery.isLoading || !issueQuery.data : false;

  const resolvedMonitorDomain =
    monitorDomain || issueQuery.data?.monitors[0]?.domain;

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (!resolvedMonitorDomain || issueQuery.isError) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="h6">Could not load monitor</Typography>
        <Typography variant="body1">
          There was a problem loading the requested monitor timeline
        </Typography>
      </Stack>
    );
  }

  return (
    <MonitorTimeline
      monitorDomain={resolvedMonitorDomain}
      focusedIssueId={issueId}
    />
  );
}
