export const mccData = [
  {
    category: "Agricultural Services",
    code: "0742",
    description: "Veterinary services",
  },
  {
    category: "Agricultural Services",
    code: "0743",
    description: "Wine producers",
  },
  {
    category: "Agricultural Services",
    code: "7044",
    description: "Champagne producers",
  },
  {
    category: "Agricultural Services",
    code: "0763",
    description: "Agricultural Cooperatives",
  },
  {
    category: "Agricultural Services",
    code: "0780",
    description: "Landscaping and horticultural services",
  },
  {
    category: "Contracted Services",
    code: "1353",
    description: "Dia (Spain)-Hypermarkets of Food",
  },
  {
    category: "Contracted Services",
    code: "1406",
    description: "H&M Moda (Spain)-Retail Merchants",
  },
  {
    category: "Contracted Services",
    code: "1520",
    description: "General contractors — residential and commercial",
  },
  {
    category: "Contracted Services",
    code: "1711",
    description: "Heating, plumbing and air-conditioning contractors",
  },
  {
    category: "Contracted Services",
    code: "1731",
    description: "Electrical contractors",
  },
  {
    category: "Contracted Services",
    code: "1740",
    description:
      "Masonry, stonework, tile setting, plastering and insulation contractors",
  },
  {
    category: "Contracted Services",
    code: "1750",
    description: "Carpentry contractors",
  },
  {
    category: "Contracted Services",
    code: "1761",
    description: "Roofing, siding and sheet metal work contractors",
  },
  {
    category: "Contracted Services",
    code: "1771",
    description: "Concrete work contractors",
  },
  {
    category: "Contracted Services",
    code: "1799",
    description: "Special trade contractors — not elsewhere classified",
  },
  {
    category: "Contracted Services",
    code: "2741",
    description: "Miscellaneous publishing and printing services",
  },
  {
    category: "Contracted Services",
    code: "2791",
    description: "Typesetting, platemaking and related services",
  },
  {
    category: "Contracted Services",
    code: "2842",
    description: "Speciality cleaning, polishing and sanitation preparations",
  },
  {
    category: "Transportation Services",
    code: "4011",
    description: "Railroads",
  },
  {
    category: "Transportation Services",
    code: "4111",
    description:
      "Local and suburban commuter passenger transportation, including ferries",
  },
  {
    category: "Transportation Services",
    code: "4112",
    description: "Passenger railways",
  },
  {
    category: "Transportation Services",
    code: "4119",
    description: "Ambulance Services",
  },
  {
    category: "Transportation Services",
    code: "4121",
    description: "Taxi-cabs and limousines",
  },
  {
    category: "Transportation Services",
    code: "4131",
    description: "Bus Lines",
  },
  {
    category: "Transportation Services",
    code: "4214",
    description:
      "Motor freight carriers and trucking — local and long distance, moving and storage companies and local delivery",
  },
  {
    category: "Transportation Services",
    code: "4215",
    description: "Courier services — air and ground and freight forwarders",
  },
  {
    category: "Transportation Services",
    code: "4225",
    description:
      "Public warehousing and storage — farm products, refrigerated goods and household goods",
  },
  {
    category: "Transportation Services",
    code: "4411",
    description: "Steamships and cruise lines",
  },
  {
    category: "Transportation Services",
    code: "4457",
    description: "Boat Rentals and Leasing",
  },
  {
    category: "Transportation Services",
    code: "4468",
    description: "Marinas, marine service and supplies",
  },
  {
    category: "Transportation Services",
    code: "4511",
    description: "Airlines and Air Carriers (Not Elsewhere Classified)",
  },
  {
    category: "Transportation Services",
    code: "4582",
    description: "Airports, Flying Fields, and Airport Terminals",
  },
  {
    category: "Transportation Services",
    code: "4722",
    description: "Travel agencies and tour operators",
  },
  {
    category: "Transportation Services",
    code: "4723",
    description: "Package Tour Operators – Germany Only",
  },
  {
    category: "Transportation Services",
    code: "4784",
    description: "Tolls and bridge fees",
  },
  {
    category: "Transportation Services",
    code: "4789",
    description: "Transportation services — not elsewhere classified",
  },
  {
    category: "Utility Services",
    code: "4812",
    description: "Telecommunication equipment and telephone sales",
  },
  {
    category: "Utility Services",
    code: "4813",
    description:
      "Key-entry Telecom Merchant providing single local and long-distance phone calls using a central access number in a non–face-to-face environment using key entry",
  },
  {
    category: "Utility Services",
    code: "4814",
    description:
      "Telecommunication services, including local and long distance calls, credit card calls, calls through use of magnetic stripe reading telephones and faxes",
  },
  {
    category: "Utility Services",
    code: "4815",
    description: "Monthly summary telephone charges",
  },
  {
    category: "Utility Services",
    code: "4816",
    description: "Computer network/information services",
  },
  {
    category: "Utility Services",
    code: "4821",
    description: "Telegraph services",
  },
  {
    category: "Utility Services",
    code: "4829",
    description: "Wire transfers and money orders",
  },
  {
    category: "Utility Services",
    code: "4899",
    description: "Cable and other pay television services",
  },
  {
    category: "Utility Services",
    code: "4900",
    description: "Utilities — electric, gas, water and sanitary",
  },
  {
    category: "Retail Outlet Services",
    code: "5013",
    description: "Motor vehicle supplies and new parts",
  },
  {
    category: "Retail Outlet Services",
    code: "5021",
    description: "Office and commercial furniture",
  },
  {
    category: "Retail Outlet Services",
    code: "5039",
    description: "Construction materials — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5044",
    description: "Office, photographic, photocopy and microfilm equipment",
  },
  {
    category: "Retail Outlet Services",
    code: "5045",
    description:
      "Computers, computer peripheral equipment — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5046",
    description: "Commercial equipment — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5047",
    description:
      "Dental/laboratory/medical/ophthalmic hospital equipment and supplies",
  },
  {
    category: "Retail Outlet Services",
    code: "5051",
    description: "Metal service centres and offices",
  },
  {
    category: "Retail Outlet Services",
    code: "5065",
    description: "Electrical parts and equipment",
  },
  {
    category: "Retail Outlet Services",
    code: "5072",
    description: "Hardware equipment and supplies",
  },
  {
    category: "Retail Outlet Services",
    code: "5074",
    description: "Plumbing and heating equipment and supplies",
  },
  {
    category: "Retail Outlet Services",
    code: "5085",
    description: "Industrial supplies — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5094",
    description: "Precious stones and metals, watches and jewellery",
  },
  {
    category: "Retail Outlet Services",
    code: "5099",
    description: "Durable goods — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5111",
    description: "Stationery, office supplies and printing and writing paper",
  },
  {
    category: "Retail Outlet Services",
    code: "5122",
    description: "Drugs, drug proprietors",
  },
  {
    category: "Retail Outlet Services",
    code: "5131",
    description: "Piece goods, notions and other dry goods",
  },
  {
    category: "Retail Outlet Services",
    code: "5137",
    description:
      "Men’s, women’s and children’s uniforms and commercial clothing",
  },
  {
    category: "Retail Outlet Services",
    code: "5139",
    description: "Commercial footwear",
  },
  {
    category: "Retail Outlet Services",
    code: "5169",
    description: "Chemicals and allied products — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5172",
    description: "Petroleum and petroleum products",
  },
  {
    category: "Retail Outlet Services",
    code: "5192",
    description: "Books, Periodicals and Newspapers",
  },
  {
    category: "Retail Outlet Services",
    code: "5193",
    description: "Florists’ supplies, nursery stock and flowers",
  },
  {
    category: "Retail Outlet Services",
    code: "5198",
    description: "Paints, varnishes and supplies",
  },
  {
    category: "Retail Outlet Services",
    code: "5199",
    description: "Non-durable goods — not elsewhere classified",
  },
  {
    category: "Retail Outlet Services",
    code: "5200",
    description: "Home supply warehouse outlets",
  },
  {
    category: "Retail Outlet Services",
    code: "5211",
    description: "Lumber and building materials outlets",
  },
  {
    category: "Retail Outlet Services",
    code: "5231",
    description: "Glass, paint and wallpaper shops",
  },
  {
    category: "Retail Outlet Services",
    code: "5251",
    description: "Hardware shops",
  },
  {
    category: "Retail Outlet Services",
    code: "5261",
    description: "Lawn and garden supply outlets, including nurseries",
  },
  {
    category: "Retail Outlet Services",
    code: "5262",
    description: "Marketplaces (online Marketplaces)",
  },
  {
    category: "Retail Outlet Services",
    code: "5271",
    description: "Mobile home dealers",
  },
  {
    category: "Retail Outlet Services",
    code: "5299",
    description: "Warehouse Club Gas",
  },
  {
    category: "Retail Outlet Services",
    code: "5300",
    description: "Wholesale clubs",
  },
  {
    category: "Retail Outlet Services",
    code: "5309",
    description: "Duty-free shops",
  },
  {
    category: "Retail Outlet Services",
    code: "5310",
    description: "Discount shops",
  },
  {
    category: "Retail Outlet Services",
    code: "5311",
    description: "Department stores",
  },
  {
    category: "Retail Outlet Services",
    code: "5331",
    description: "Variety stores",
  },
  {
    category: "Retail Outlet Services",
    code: "5333",
    description: "Hypermarkets of food",
  },
  {
    category: "Retail Outlet Services",
    code: "5399",
    description: "Miscellaneous general merchandise",
  },
  {
    category: "Retail Outlet Services",
    code: "5411",
    description: "Groceries and supermarkets",
  },
  {
    category: "Retail Outlet Services",
    code: "5422",
    description: "Freezer and locker meat provisioners",
  },
  {
    category: "Retail Outlet Services",
    code: "5441",
    description: "Candy, nut and confectionery shops",
  },
  { category: "Retail Outlet Services", code: "5451", description: "Dairies" },
  { category: "Retail Outlet Services", code: "5462", description: "Bakeries" },
  {
    category: "Retail Outlet Services",
    code: "5499",
    description:
      "Miscellaneous food shops — convenience and speciality retail outlets",
  },
  {
    category: "Retail Outlet Services",
    code: "5511",
    description:
      "Car and truck dealers (new and used) sales, services, repairs, parts and leasing",
  },
  {
    category: "Retail Outlet Services",
    code: "5521",
    description:
      "Car and truck dealers (used only) sales, service, repairs, parts and leasing",
  },
  {
    category: "Retail Outlet Services",
    code: "5531",
    description: "Auto Store",
  },
  {
    category: "Retail Outlet Services",
    code: "5532",
    description: "Automotive Tire Stores",
  },
  {
    category: "Retail Outlet Services",
    code: "5533",
    description: "Automotive Parts and Accessories Stores",
  },
  {
    category: "Retail Outlet Services",
    code: "5541",
    description: "Service stations (with or without ancillary services)",
  },
  {
    category: "Retail Outlet Services",
    code: "5542",
    description: "Automated Fuel Dispensers",
  },
  {
    category: "Retail Outlet Services",
    code: "5551",
    description: "Boat Dealers",
  },
  {
    category: "Retail Outlet Services",
    code: "5552",
    description: "Electric Vehicle Charging",
  },
  {
    category: "Retail Outlet Services",
    code: "5561",
    description: "Camper, recreational and utility trailer dealers",
  },
  {
    category: "Retail Outlet Services",
    code: "5571",
    description: "Motorcycle shops and dealers",
  },
  {
    category: "Retail Outlet Services",
    code: "5592",
    description: "Motor home dealers",
  },
  {
    category: "Retail Outlet Services",
    code: "5598",
    description: "Snowmobile dealers",
  },
  {
    category: "Retail Outlet Services",
    code: "5599",
    description:
      "Miscellaneous automotive, aircraft and farm equipment dealers — not elsewhere classified",
  },
  {
    category: "Clothing Stores",
    code: "5611",
    description: "Men’s and boys’ clothing and accessory shops",
  },
  {
    category: "Clothing Stores",
    code: "5621",
    description: "Women’s ready-to-wear shops",
  },
  {
    category: "Clothing Stores",
    code: "5631",
    description: "Women’s accessory and speciality shops",
  },
  {
    category: "Clothing Stores",
    code: "5641",
    description: "Children’s and infants’ wear shops",
  },
  {
    category: "Clothing Stores",
    code: "5651",
    description: "Family clothing shops",
  },
  {
    category: "Clothing Stores",
    code: "5655",
    description: "Sports and riding apparel shops",
  },
  { category: "Clothing Stores", code: "5661", description: "Shoe shops" },
  {
    category: "Clothing Stores",
    code: "5681",
    description: "Furriers and fur shops",
  },
  {
    category: "Clothing Stores",
    code: "5691",
    description: "Men’s and women’s clothing shops",
  },
  {
    category: "Clothing Stores",
    code: "5697",
    description: "Tailors, seamstresses, mending and alterations",
  },
  {
    category: "Clothing Stores",
    code: "5698",
    description: "Wig and toupee shops",
  },
  {
    category: "Clothing Stores",
    code: "5699",
    description: "Miscellaneous apparel and accessory shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5712",
    description:
      "Furniture, home furnishings and equipment shops and manufacturers, except appliances",
  },
  {
    category: "Miscellaneous Stores",
    code: "5713",
    description: "Floor covering services",
  },
  {
    category: "Miscellaneous Stores",
    code: "5714",
    description: "Drapery, window covering and upholstery shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5715",
    description: "Alcoholic beverage wholesalers",
  },
  {
    category: "Miscellaneous Stores",
    code: "5718",
    description: "Fireplaces, fireplace screens and accessories shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5719",
    description: "Miscellaneous home furnishing speciality shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5722",
    description: "Household appliance shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5732",
    description: "Electronics shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5733",
    description: "Music shops — musical instruments, pianos and sheet music",
  },
  {
    category: "Miscellaneous Stores",
    code: "5734",
    description: "Computer software outlets",
  },
  {
    category: "Miscellaneous Stores",
    code: "5735",
    description: "Record shops",
  },
  { category: "Miscellaneous Stores", code: "5811", description: "Caterers" },
  {
    category: "Miscellaneous Stores",
    code: "5812",
    description: "Eating places and restaurants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5813",
    description:
      "Drinking places (alcoholic beverages) — bars, taverns, night-clubs, cocktail lounges and discothèques",
  },
  {
    category: "Miscellaneous Stores",
    code: "5814",
    description: "Fast food restaurants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5815",
    description: "Digital Goods Media – Books, Movies, Music",
  },
  {
    category: "Miscellaneous Stores",
    code: "5816",
    description: "Digital Goods – Games",
  },
  {
    category: "Miscellaneous Stores",
    code: "5817",
    description: "Digital Goods – Applications (Excludes Games)",
  },
  {
    category: "Miscellaneous Stores",
    code: "5818",
    description: "Digital Goods – Large Digital Goods Merchant",
  },
  {
    category: "Miscellaneous Stores",
    code: "5912",
    description: "Drug stores and pharmacies",
  },
  {
    category: "Miscellaneous Stores",
    code: "5921",
    description: "Package shops — beer, wine and liquor",
  },
  {
    category: "Miscellaneous Stores",
    code: "5931",
    description: "Used merchandise and second-hand shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5932",
    description: "Antique Shops – Sales, Repairs, and Restoration Services",
  },
  { category: "Miscellaneous Stores", code: "5933", description: "Pawn shops" },
  {
    category: "Miscellaneous Stores",
    code: "5935",
    description: "Wrecking and salvage yards",
  },
  {
    category: "Miscellaneous Stores",
    code: "5937",
    description: "Antique Reproductions",
  },
  {
    category: "Miscellaneous Stores",
    code: "5940",
    description: "Bicycle Shops – Sales and Service",
  },
  {
    category: "Miscellaneous Stores",
    code: "5941",
    description: "Sporting goods shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5942",
    description: "Book Stores",
  },
  {
    category: "Miscellaneous Stores",
    code: "5943",
    description: "Stationery, office and school supply shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5944",
    description: "Jewellery, watch, clock and silverware shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5945",
    description: "Hobby, toy and game shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5946",
    description: "Camera and photographic supply shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5947",
    description: "Gift, card, novelty and souvenir shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5948",
    description: "Luggage and leather goods shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5949",
    description: "Sewing, needlework, fabric and piece goods shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5950",
    description: "Glassware and crystal shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5960",
    description: "Direct marketing — insurance services",
  },
  {
    category: "Miscellaneous Stores",
    code: "5961",
    description: "Mail Order Houses Including Catalog Order Stores",
  },
  {
    category: "Miscellaneous Stores",
    code: "5962",
    description: "Telemarketing — travel-related arrangement services",
  },
  {
    category: "Miscellaneous Stores",
    code: "5963",
    description: "Door-to-door sales",
  },
  {
    category: "Miscellaneous Stores",
    code: "5964",
    description: "Direct marketing — catalogue merchants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5965",
    description:
      "Direct marketing — combination catalogue and retail merchants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5966",
    description: "Direct marketing — outbound telemarketing merchants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5967",
    description: "Direct marketing — inbound telemarketing merchants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5968",
    description: "Direct marketing — continuity/subscription merchants",
  },
  {
    category: "Miscellaneous Stores",
    code: "5969",
    description: "Direct marketing/direct marketers — not elsewhere classified",
  },
  {
    category: "Miscellaneous Stores",
    code: "5970",
    description: "Artist’s Supply and Craft Shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5971",
    description: "Art Dealers and Galleries",
  },
  {
    category: "Miscellaneous Stores",
    code: "5972",
    description: "Stamp and coin shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5973",
    description: "Religious goods and shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5974",
    description: "Rubber Stamp Store",
  },
  {
    category: "Miscellaneous Stores",
    code: "5975",
    description: "Hearing aids — sales, service and supplies",
  },
  {
    category: "Miscellaneous Stores",
    code: "5976",
    description: "Orthopaedic goods and prosthetic devices",
  },
  {
    category: "Miscellaneous Stores",
    code: "5977",
    description: "Cosmetic Stores",
  },
  {
    category: "Miscellaneous Stores",
    code: "5978",
    description: "Typewriter outlets — sales, service and rentals",
  },
  {
    category: "Miscellaneous Stores",
    code: "5983",
    description: "Fuel dealers — fuel oil, wood, coal and liquefied petroleum",
  },
  { category: "Miscellaneous Stores", code: "5992", description: "Florists" },
  {
    category: "Miscellaneous Stores",
    code: "5993",
    description: "Cigar shops and stands",
  },
  {
    category: "Miscellaneous Stores",
    code: "5994",
    description: "Newsagents and news-stands",
  },
  {
    category: "Miscellaneous Stores",
    code: "5995",
    description: "Pet shops, pet food and supplies",
  },
  {
    category: "Miscellaneous Stores",
    code: "5996",
    description: "Swimming pools — sales, supplies and services",
  },
  {
    category: "Miscellaneous Stores",
    code: "5997",
    description: "Electric razor outlets — sales and service",
  },
  {
    category: "Miscellaneous Stores",
    code: "5998",
    description: "Tent and awning shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "5999",
    description: "Miscellaneous and speciality retail outlets",
  },
  {
    category: "Miscellaneous Stores",
    code: "6010",
    description: "Financial institutions — manual cash disbursements",
  },
  {
    category: "Miscellaneous Stores",
    code: "6011",
    description: "Financial institutions — automated cash disbursements",
  },
  {
    category: "Miscellaneous Stores",
    code: "6012",
    description: "Financial institutions — merchandise and services",
  },
  {
    category: "Miscellaneous Stores",
    code: "6050",
    description: "Quasi Cash—Customer Financial Institution",
  },
  {
    category: "Miscellaneous Stores",
    code: "6051",
    description: "Liquid and Cryptocurrency Assets",
  },
  {
    category: "Miscellaneous Stores",
    code: "6211",
    description: "Securities — brokers and dealers",
  },
  {
    category: "Miscellaneous Stores",
    code: "6300",
    description: "Insurance sales, underwriting and premiums",
  },
  {
    category: "Miscellaneous Stores",
    code: "6381",
    description: "Insurance–Premiums",
  },
  {
    category: "Miscellaneous Stores",
    code: "6399",
    description:
      "Insurance, Not Elsewhere Classified ( no longer valid for first presentment work)",
  },
  {
    category: "Miscellaneous Stores",
    code: "6513",
    description: "Real Estate Agents and Managers",
  },
  {
    category: "Miscellaneous Stores",
    code: "6529",
    description: "Remote Stored Value Load — Member Financial Institution",
  },
  {
    category: "Miscellaneous Stores",
    code: "6530",
    description: "Remove Stored Value Load — Merchant",
  },
  {
    category: "Miscellaneous Stores",
    code: "6532",
    description: "Payment Transaction—Customer Financial Institution",
  },
  {
    category: "Miscellaneous Stores",
    code: "6533",
    description: "Payment Transaction—Merchant",
  },
  {
    category: "Miscellaneous Stores",
    code: "6535",
    description: "Value Purchase–Member Financial Institution",
  },
  {
    category: "Miscellaneous Stores",
    code: "6536",
    description: "MoneySend Intracountry",
  },
  {
    category: "Miscellaneous Stores",
    code: "6537",
    description: "MoneySend Intercountry",
  },
  {
    category: "Miscellaneous Stores",
    code: "6538",
    description: "MoneySend Funding",
  },
  {
    category: "Miscellaneous Stores",
    code: "6539",
    description: "Funding Transaction (Excluding MoneySend)",
  },
  {
    category: "Miscellaneous Stores",
    code: "6540",
    description: "Non-Financial Institutions – Stored Value Card Purchase/Load",
  },
  {
    category: "Miscellaneous Stores",
    code: "6611",
    description: "Overpayments",
  },
  {
    category: "Miscellaneous Stores",
    code: "6760",
    description: "Savings Bonds",
  },
  {
    category: "Miscellaneous Stores",
    code: "7011",
    description: "Lodging — hotels, motels and resorts",
  },
  { category: "Miscellaneous Stores", code: "7012", description: "Timeshares" },
  {
    category: "Miscellaneous Stores",
    code: "7032",
    description: "Sporting and recreational camps",
  },
  {
    category: "Miscellaneous Stores",
    code: "7033",
    description: "Trailer parks and camp-sites",
  },
  {
    category: "Miscellaneous Stores",
    code: "7210",
    description: "Laundry, cleaning and garment services",
  },
  {
    category: "Miscellaneous Stores",
    code: "7211",
    description: "Laundry services — family and commercial",
  },
  {
    category: "Miscellaneous Stores",
    code: "7216",
    description: "Dry cleaners",
  },
  {
    category: "Miscellaneous Stores",
    code: "7217",
    description: "Carpet and upholstery cleaning",
  },
  {
    category: "Miscellaneous Stores",
    code: "7221",
    description: "Photographic studios",
  },
  {
    category: "Miscellaneous Stores",
    code: "7230",
    description: "Barber and Beauty Shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "7251",
    description:
      "Shoe repair shops, shoe shine parlours and hat cleaning shops",
  },
  {
    category: "Miscellaneous Stores",
    code: "7261",
    description: "Funeral services and crematoriums",
  },
  {
    category: "Miscellaneous Stores",
    code: "7273",
    description: "Dating and escort services",
  },
  {
    category: "Miscellaneous Stores",
    code: "7276",
    description: "Tax preparation services",
  },
  {
    category: "Miscellaneous Stores",
    code: "7277",
    description: "Counselling services — debt, marriage and personal",
  },
  {
    category: "Miscellaneous Stores",
    code: "7278",
    description: "Buying and shopping services and clubs",
  },
  {
    category: "Miscellaneous Stores",
    code: "7280",
    description: "Hospital Patient-Personal Funds Withdrawal",
  },
  {
    category: "Miscellaneous Stores",
    code: "7295",
    description: "Babysitting Services",
  },
  {
    category: "Miscellaneous Stores",
    code: "7296",
    description: "Clothing rentals — costumes, uniforms and formal wear",
  },
  {
    category: "Miscellaneous Stores",
    code: "7297",
    description: "Massage parlours",
  },
  {
    category: "Miscellaneous Stores",
    code: "7298",
    description: "Health and beauty spas",
  },
  {
    category: "Miscellaneous Stores",
    code: "7299",
    description: "Miscellaneous personal services — not elsewhere classified",
  },
  {
    category: "Business Services",
    code: "7311",
    description: "Advertising Services",
  },
  {
    category: "Business Services",
    code: "7321",
    description: "Consumer credit reporting agencies",
  },
  {
    category: "Business Services",
    code: "7322",
    description: "Debt collection agencies",
  },
  {
    category: "Business Services",
    code: "7332",
    description: "Blueprinting and Photocopying Services",
  },
  {
    category: "Business Services",
    code: "7333",
    description: "Commercial photography, art and graphics",
  },
  {
    category: "Business Services",
    code: "7338",
    description: "Quick copy, reproduction and blueprinting services",
  },
  {
    category: "Business Services",
    code: "7339",
    description: "Stenographic and secretarial support services",
  },
  {
    category: "Business Services",
    code: "7342",
    description: "Exterminating and disinfecting services",
  },
  {
    category: "Business Services",
    code: "7349",
    description: "Cleaning, maintenance and janitorial services",
  },
  {
    category: "Business Services",
    code: "7361",
    description: "Employment agencies and temporary help services",
  },
  {
    category: "Business Services",
    code: "7372",
    description:
      "Computer programming, data processing and integrated systems design services",
  },
  {
    category: "Business Services",
    code: "7375",
    description: "Information retrieval services",
  },
  {
    category: "Business Services",
    code: "7379",
    description:
      "Computer maintenance and repair services — not elsewhere classified",
  },
  {
    category: "Business Services",
    code: "7392",
    description: "Management, consulting and public relations services",
  },
  {
    category: "Business Services",
    code: "7393",
    description:
      "Detective  agencies,  protective  agencies  and  security  services,  including  armoured  cars  and guard dogs",
  },
  {
    category: "Business Services",
    code: "7394",
    description: "Equipment, tool, furniture and appliance rentals and leasing",
  },
  {
    category: "Business Services",
    code: "7395",
    description: "Photofinishing laboratories and photo developing",
  },
  {
    category: "Business Services",
    code: "7399",
    description: "Business services — not elsewhere classified",
  },
  { category: "Business Services", code: "7511", description: "Truck Stop" },
  {
    category: "Business Services",
    code: "7512",
    description: "Automobile Rental Agency—not elsewhere classified",
  },
  {
    category: "Business Services",
    code: "7513",
    description: "Truck and utility trailer rentals",
  },
  {
    category: "Business Services",
    code: "7519",
    description: "Motor home and recreational vehicle rentals",
  },
  {
    category: "Business Services",
    code: "7523",
    description: "Parking lots and garages",
  },
  {
    category: "Business Services",
    code: "7524",
    description: "Express Payment Service Merchants–Parking Lots and Garages",
  },
  {
    category: "Business Services",
    code: "7531",
    description: "Automotive Body Repair Shops",
  },
  {
    category: "Business Services",
    code: "7534",
    description: "Tire retreading and repair shops",
  },
  {
    category: "Business Services",
    code: "7535",
    description: "Automotive Paint Shops",
  },
  {
    category: "Business Services",
    code: "7538",
    description: "Automotive Service Shops (Non-Dealer)",
  },
  {
    category: "Business Services",
    code: "7539",
    description: "Automotive Service Shops (Spain) - Other Merchant Categories",
  },
  { category: "Business Services", code: "7542", description: "Car washes" },
  {
    category: "Business Services",
    code: "7549",
    description: "Towing services",
  },
  {
    category: "Business Services",
    code: "7622",
    description: "Electronics repair shops",
  },
  {
    category: "Business Services",
    code: "7623",
    description: "Air Conditioning and Refrigeration Repair Shops",
  },
  {
    category: "Business Services",
    code: "7629",
    description: "Electrical and small appliance repair shops",
  },
  {
    category: "Business Services",
    code: "7631",
    description: "Watch, clock and jewellery repair shops",
  },
  {
    category: "Business Services",
    code: "7641",
    description: "Furniture reupholstery, repair and refinishing",
  },
  {
    category: "Business Services",
    code: "7692",
    description: "Welding services",
  },
  {
    category: "Business Services",
    code: "7699",
    description: "Miscellaneous repair shops and related services",
  },
  {
    category: "Business Services",
    code: "7800",
    description: "Government-Owned Lotteries (US Region only)",
  },
  {
    category: "Business Services",
    code: "7801",
    description:
      "Government Licensed On-Line Casinos (On-Line Gambling) (US Region only)",
  },
  {
    category: "Business Services",
    code: "7802",
    description: "Government-Licensed Horse/Dog Racing (US Region only)",
  },
  {
    category: "Business Services",
    code: "7829",
    description: "Motion picture and video tape production and distribution",
  },
  {
    category: "Business Services",
    code: "7832",
    description: "Motion picture theatres",
  },
  {
    category: "Business Services",
    code: "7833",
    description: "Express Payment Service — Motion Picture Theater",
  },
  {
    category: "Business Services",
    code: "7841",
    description: "Video tape rentals",
  },
  {
    category: "Business Services",
    code: "7911",
    description: "Dance halls, studios and schools",
  },
  {
    category: "Business Services",
    code: "7922",
    description:
      "Theatrical producers (except motion pictures) and ticket agencies",
  },
  {
    category: "Business Services",
    code: "7929",
    description:
      "Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)",
  },
  {
    category: "Business Services",
    code: "7932",
    description: "Billiard and Pool Establishments",
  },
  {
    category: "Business Services",
    code: "7933",
    description: "Bowling Alleys",
  },
  {
    category: "Business Services",
    code: "7941",
    description:
      "Commercial sports, professional sports clubs, athletic fields and sports promoters",
  },
  {
    category: "Business Services",
    code: "7991",
    description: "Tourist attractions and exhibits",
  },
  {
    category: "Business Services",
    code: "7992",
    description: "Public golf courses",
  },
  {
    category: "Business Services",
    code: "7993",
    description: "Video amusement game supplies",
  },
  {
    category: "Business Services",
    code: "7994",
    description: "Video game arcades and establishments",
  },
  {
    category: "Business Services",
    code: "7995",
    description:
      "Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, and Wagers at Race Tracks",
  },
  {
    category: "Business Services",
    code: "7996",
    description: "Amusement Parks, Circuses, Carnivals, and Fortune Tellers",
  },
  {
    category: "Business Services",
    code: "7997",
    description:
      "Membership clubs (sports, recreation, athletic), country clubs and private golf courses",
  },
  {
    category: "Business Services",
    code: "7998",
    description: "Aquariums, Seaquariums, Dolphinariums, and Zoos",
  },
  {
    category: "Business Services",
    code: "7999",
    description: "Recreation services — not elsewhere classified",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8011",
    description: "Doctors and physicians — not elsewhere classified",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8021",
    description: "Dentists and orthodontists",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8031",
    description: "Osteopaths",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8041",
    description: "Chiropractors",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8042",
    description: "Optometrists and ophthalmologists",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8043",
    description: "Opticians, optical goods and eyeglasses",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8044",
    description: "Optical Goods and Eyeglasses",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8049",
    description: "Podiatrists and chiropodists",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8050",
    description: "Nursing and personal care facilities",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8062",
    description: "Hospitals",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8071",
    description: "Medical and dental laboratories",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8099",
    description:
      "Medical services and health practitioners — not elsewhere classified",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8111",
    description: "Legal services and attorneys",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8211",
    description: "Elementary and secondary schools",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8220",
    description:
      "Colleges, universities, professional schools and junior colleges",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8241",
    description: "Correspondence schools",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8244",
    description: "Business and secretarial schools",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8249",
    description: "Trade and vocational schools",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8299",
    description: "Schools and educational services — not elsewhere classified",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8351",
    description: "Child care services",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8398",
    description: "Charitable and social service organizations",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8641",
    description: "Civic, social and fraternal associations",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8651",
    description: "Political organizations",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8661",
    description: "Religious organizations",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8675",
    description: "Automobile Associations",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8699",
    description: "Membership organization — not elsewhere classified",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8734",
    description: "Testing laboratories (non-medical)",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8911",
    description: "Architectural, Engineering, and Surveying Services",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8931",
    description: "Accounting, Auditing, and Bookkeeping Services",
  },
  {
    category: "Professional Services and Membership Organizations",
    code: "8999",
    description: "Professional services — not elsewhere classified",
  },
  {
    category: "Government Services",
    code: "9034",
    description: "I-Purchasing Pilot",
  },
  {
    category: "Government Services",
    code: "9211",
    description: "Court costs, including alimony and child support",
  },
  { category: "Government Services", code: "9222", description: "Fines" },
  {
    category: "Government Services",
    code: "9223",
    description: "Bail and Bond Payments",
  },
  {
    category: "Government Services",
    code: "9311",
    description: "Tax payments",
  },
  {
    category: "Government Services",
    code: "9399",
    description: "Government services — not elsewhere classified",
  },
  {
    category: "Government Services",
    code: "9402",
    description: "Postal services — government only",
  },
  {
    category: "Government Services",
    code: "9405",
    description: "U.S. Federal Government Agencies or Departments",
  },
  {
    category: "Government Services",
    code: "9406",
    description: "Government-Owned Lotteries (Non-U.S. region)",
  },
  {
    category: "Government Services",
    code: "9700",
    description: "Automated Referral Service",
  },
  {
    category: "Government Services",
    code: "9701",
    description: "Visa Credential Server",
  },
  {
    category: "Government Services",
    code: "9702",
    description: "Emergency Services (GCAS) (Visa use only)",
  },
  {
    category: "Government Services",
    code: "9751",
    description: "UK Supermarkets, Electronic Hot File",
  },
  {
    category: "Government Services",
    code: "9752",
    description: "UK Petrol Stations, Electronic Hot File",
  },
  {
    category: "Government Services",
    code: "9754",
    description: "Gambling-Horse, Dog Racing, State Lottery",
  },
  {
    category: "Government Services",
    code: "9950",
    description: "Intra-Company Purchases",
  },
];
