import { Divider, Box, Paper, Grid, Typography } from "@mui/material";

import SubmittedEmail from "./SubmittedEmail";
import SubmittedAddress from "./SubmittedAddress";
import SubmittedPhone from "./SubmittedPhone";
import SubmittedPerson from "./SubmittedPerson";
import APIApplication from "../../../types/APIApplication";
import APIPhone from "../../../types/APIPhone";
import APIAddress from "../../../types/APIAddress";
import APIEmail from "../../../types/APIEmail";
import APIPerson from "../../../types/APIPerson";

export default function Application({
  application,
  primary_phone_number,
  additional_phone_numbers,
  primary_address,
  additional_addresses,
  email_addresses,
  people,
}: {
  application: APIApplication | null;
  primary_phone_number: APIPhone | null;
  additional_phone_numbers: APIPhone[] | null;
  primary_address: APIAddress | null;
  additional_addresses: APIAddress[] | null;
  email_addresses: APIEmail[] | null;
  people: APIPerson[] | null;
}) {
  const getSubmittedAddress = () => {
    const addresses = additional_addresses || [];
    if (primary_address) {
      addresses.push(primary_address);
    }
    return addresses.find((addr) => addr.source === "submitted");
  };
  const submittedAddress = getSubmittedAddress();
  const submittedEmail = email_addresses?.find(
    (em) => em.source === "submitted"
  );
  const getsubmittedPhone = () => {
    const phones = [...(additional_phone_numbers || [])];
    if (primary_phone_number) {
      phones.push(primary_phone_number);
    }
    return phones.find((ph) => ph.source === "submitted");
  };
  const submittedPhone = getsubmittedPhone();
  const submittedPerson = people?.find(
    (person) => person.source === "submitted"
  );

  if (
    ![submittedAddress, submittedPhone, submittedPerson, submittedEmail].some(
      Boolean
    )
  ) {
    return null;
  }

  return (
    <Paper
      elevation={2}
      sx={{
        p: 0,
        overflow: "hidden",
      }}
    >
      <Box sx={{ pl: 3, pt: 3, pb: 3 }}>
        <Typography variant="h6" component="h3" sx={{ fontWeight: "normal" }}>
          Application
        </Typography>

        <Box sx={{ mt: 1 }}>
          <Grid
            container
            spacing={0}
            marginTop={1}
            marginRight={-5}
            columnSpacing={{ md: 5 }}
            rowSpacing={{ md: 0 }}
          >
            <Grid item sm={12} md={6} lg={3}>
              <SubmittedEmail
                email={submittedEmail}
                emailBusinessConnections={
                  application?.email_business_connections
                }
              />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ ml: 0, mr: -2 }} />
            <Grid item sm={12} md={6} lg={3}>
              <SubmittedPhone
                phone={submittedPhone}
                phoneBusinessConnections={
                  application?.phone_business_connections
                }
              />
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                ml: 0,
                mr: -2,
                visibility: {
                  lg: "visible",
                  md: "hidden",
                  sm: "hidden",
                  xs: "hidden",
                },
              }}
            />
            <Grid item sm={12} md={6} lg={3}>
              <SubmittedAddress
                address={submittedAddress}
                addressBusinessConnections={
                  application?.address_business_connections
                }
              />
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ ml: 0, mr: -2 }} />
            <Grid item sm={12} md={6} lg={3}>
              <SubmittedPerson
                person={submittedPerson || null}
                personBusinessConnections={
                  application?.person_business_connections
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
