import { uniq } from "lodash";
import { Paper, Grid, Typography } from "@mui/material";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";

import BooleanAttribute from "./BooleanAttribute";
import Attribute from "./Attribute";
import Tooltip from "../Tooltip";
import { getDisplayAddress, humanizeString } from "../../utilities/formatting";
import APIAddress from "../../types/APIAddress";
import APIWebsiteTraffic from "../../types/APIWebsiteTraffic";
import APIPerson from "../../types/APIPerson";
import APIDomain from "../../types/APIDomain";
import APIPhone from "../../types/APIPhone";

export default function LocationData({
  primaryAddress,
  primaryPhoneNumber,
  addressProperties,
  websiteTraffic,
  people,
  domain,
}: {
  primaryAddress: APIAddress | null;
  primaryPhoneNumber?: APIPhone | null | undefined;
  websiteTraffic: APIWebsiteTraffic | null;
  people: APIPerson[] | null;
  domain: APIDomain | null;
  addressProperties: any;
}) {
  return (
    <Paper
      elevation={2}
      sx={{
        p: 3,
        mb: 3,
      }}
    >
      <Typography variant="h6" component="h3" marginBottom={1}>
        Location Data
      </Typography>

      <Attribute label="Primary address">
        {primaryAddress ? getDisplayAddress(primaryAddress) : null}
      </Attribute>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <BooleanAttribute
            label="Valid?"
            labelIcon={
              <Tooltip
                title="Is the primary address known to and confirmed as valid by the postal service?"
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            // only show validity signals, no validation failures
            value={primaryAddress?.is_valid || null}
            trueIsPositive
            failureText="Unknown"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanAttribute
            label="Is shared with registered agent?"
            labelIcon={
              <Tooltip
                title={
                  addressProperties?.is_shared_with_registered_agent
                    ?.description
                }
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            value={
              !!primaryAddress && !!primaryAddress?.line_1
                ? primaryAddress?.is_shared_with_registered_agent
                : null
            }
            trueIsPositive={false}
            failureText="Unknown"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Attribute
            label="Primary address postal flags"
            labelIcon={
              <Tooltip
                title={addressProperties?.postal_service_flags?.description}
                placement="right"
                arrow
              >
                <HelpOutlineOutlinedIcon fontSize="inherit" sx={{ ml: 0.25 }} />
              </Tooltip>
            }
            failureText="Unknown"
          >
            {primaryAddress?.postal_service_flags
              ?.map(humanizeString)
              ?.join(", ") || "No Flags Found"}
          </Attribute>
        </Grid>
      </Grid>

      <br />

      {websiteTraffic && (
        <Attribute label="Primary traffic location">
          {websiteTraffic?.top_country_shares?.[0]?.name}
        </Attribute>
      )}

      {primaryPhoneNumber &&
        primaryPhoneNumber.country_code &&
        primaryPhoneNumber.country_name && (
          <Attribute label="Primary phone location">
            {primaryPhoneNumber.country_name}
          </Attribute>
        )}

      <Attribute label="People location(s)">
        {uniq((people || []).map((l) => l.country))
          .filter(Boolean)
          .join(", ")}
      </Attribute>

      {domain && (
        <>
          <Attribute label="Hosting provider location(s)">
            {uniq(
              (domain?.hosting_providers || [])
                .map((l) => l.country)
                .filter(Boolean)
            ).join(", ")}
          </Attribute>

          <Attribute label="Website registrar location">
            {domain?.registrar?.country}
          </Attribute>
        </>
      )}
    </Paper>
  );
}
