import { useTheme, IconButton } from "@mui/material";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";

export default function LowCreditsBanner() {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="welcome-banner"
          exit={{ height: 0, transform: "translateY(-100%)" }}
          style={{
            overflow: "hidden",
            background: theme.palette.error.dark,
            color: "white",
            padding: "0.5em",
            fontSize: "85%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <strong>Account credit is low.</strong>&nbsp; Please contact your
            Account Manager.
          </div>
          <IconButton
            size="small"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CloseIcon
              fontSize="small"
              style={{
                color: "white",
              }}
            />
          </IconButton>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
