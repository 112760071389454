import { Link } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { pickBy } from "lodash";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PageHeader from "../PageHeader";

import Logo from "../Logo";
import NetworkFailureToast from "../NetworkFailureToast";
import { useTrueBizApi } from "../../api";
import APICompanyMatchProfile from "../../types/APICompanyMatchProfile";

import Loading from "../Loading";
import SearchLoadingBody from "../SearchLoading";
import SearchBar from "../SearchBar";
import Delayed from "../Delayed";

interface LookupDomainBodyProps {
  domain?: string;
  advanced?: {
    businessName?: string;
    email?: string;
    phone?: string;
    applicantFullName?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
    country?: string;
  };
  matchProfileId?: UUID;
  matchProfiles?: APICompanyMatchProfile[];
  autoRun?: boolean;
}

function LookupDomainBody({
  domain: initialDomain = "",
  advanced: initialAdvanced,
  autoRun = false,
  matchProfileId: initialMatchProfileId,
  matchProfiles,
}: LookupDomainBodyProps) {
  const api = useTrueBizApi();

  const queryClient = useQueryClient();

  const [hasError, setHasError] = useState(false);
  const [reason, setReason] = useState<string | undefined>();

  const searchBarRef = useRef<() => Promise<void>>(null);

  const searchMutation = useMutation({
    mutationKey: ["submitSearch"],
    mutationFn: async (values: {
      domain?: string;
      matchProfileId?: string;
      businessName?: string;
      email?: string;
      phone?: string;
      applicantFullName?: string;
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      stateProvince?: string;
      postalCode?: string;
      country?: string;
    }) => {
      setHasError(false);
      setReason(undefined);

      const result = await api.domainLookup({
        domain: values.domain || null,
        submitted_email: values.email || null,
        submitted_phone: values.phone || null,
        submitted_business_name: values.businessName || null,
        submitted_full_name: values.applicantFullName || null,
        address_line_1: values.addressLine1 || null,
        address_line_2: values.addressLine2 || null,
        city: values.city || null,
        state_province: values.stateProvince || null,
        postal_code: values.postalCode || null,
        country: values.country || null,
        match_profile_id: values.matchProfileId || null,
      });

      // TODO: populate the get cache and eventually switch the detail page over
      // to using react-query so the load is instantaneous
      return result;
    },
    onError: async (e) => {
      // supposedly mutationResult.isError is supposed to be set
      // when an error is thrown, but this does not seem to work currently

      let reason: string | undefined = "";

      if (api.isResponseError(e)) {
        // handle 401 as a special case
        if (e.status === 401) {
          reason = `Your search failed: unauthorized`;
        } else {
          // attempt to extract known erors
          try {
            const json = await e.json();
            const message: string | undefined = json?.message;

            if (message === "Invalid input") {
              const domainErrors = (json?.errors || []).filter(
                (err: any) => err?.field_name === "domain"
              );

              if (domainErrors.length > 0) {
                reason = domainErrors
                  .flatMap((err: any) => err?.errors || [])
                  .join(" ");
              } else {
                reason = "Search input was invalid";
              }
            }
          } catch (e) {
            // pass
          }
        }
      }

      setHasError(true);
      if (reason) {
        setReason(reason);
      }
    },
  });

  const search = useCallback(
    async (values: {
      domain?: string;
      matchProfileId?: string;
      businessName?: string;
      email?: string;
      phone?: string;
      applicantFullName?: string;
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      stateProvince?: string;
      postalCode?: string;
      country?: string;
    }) => {
      // we don't want to use the isMutatingHook because it will fuck with useEffect and generally
      // be a giant pain in the ass with react strict mode
      const isAlreadySearching =
        queryClient.isMutating({
          mutationKey: ["submitSearch"],
        }) > 0;

      if (!isAlreadySearching) {
        searchMutation.reset();
        searchMutation.mutate(values);
      }
    },
    [queryClient, searchMutation]
  );

  useEffect(() => {
    if (!autoRun) return;
    if (hasError) return;
    if (searchBarRef.current) {
      searchBarRef.current();
    }
  }, [autoRun, hasError, search]);

  if (searchMutation.isSuccess) {
    if (!searchMutation.data) {
      setHasError(true);
    } else {
      const id = searchMutation.data.tracking_id;
      const domain = searchMutation.data.domain?.name || "offline";

      return (
        <Navigate
          to={`/search/${domain}/${id}`}
          state={{ newlyCompletedSearch: true }}
        />
      );
    }
  }

  if (searchMutation.isPending && !hasError) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Delayed byMs={150}>
          <PageHeader navLinks={false} divider={false} />
          <div
            style={{
              width: "100%",
              flex: "1",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SearchLoadingBody />
            </div>
          </div>
        </Delayed>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100vw",
        paddingLeft: "1em",
        paddingRight: "1em",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <PageHeader
        logo={false}
        navLinks={false}
        divider={false}
        style={{ position: "absolute", top: 0 }}
      />
      {hasError && !reason && <NetworkFailureToast />}
      <SearchBar
        onSearch={(values) => search(values)}
        domain={initialDomain}
        advanced={initialAdvanced}
        matchProfileId={initialMatchProfileId}
        matchProfiles={matchProfiles}
        style={{
          paddingBottom: "15vh",
          maxWidth: "800px",
        }}
        ref={searchBarRef}
        errorReason={reason}
        onChange={() => {
          if (reason) {
            setReason(undefined);
            setHasError(false);
          }
        }}
      >
        <Logo height="55px" style={{ marginBottom: "3em" }} />
      </SearchBar>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          display: "flex",
          flexDirection: "row",
          gap: "2em",
          padding: "1em",
        }}
      >
        <Link
          component={RouterLink}
          to="/monitoring"
          sx={{
            display: "flex",
            gap: "0.25em",
            color: "#8E8E8E",
            textDecoration: "none",
          }}
        >
          <MonitorHeartIcon style={{ color: "#D3D3D3" }} />
          <span>Monitoring</span>
        </Link>
        <Link
          component={RouterLink}
          to="/searches"
          sx={{
            display: "flex",
            gap: "0.25em",
            color: "#8E8E8E",
            textDecoration: "none",
          }}
        >
          <WatchLaterIcon style={{ color: "#D3D3D3" }} />
          <span>Search History</span>
        </Link>
      </div>
    </div>
  );
}

export default function LookupDomain() {
  const api = useTrueBizApi();

  const companyMatchProfilesQuery = useQuery({
    queryKey: ["getCompanyMatchProfiles"],
    queryFn: () => api.getCompanyMatchProfiles(),
  });

  const params = useParams();
  const [searchParams] = useSearchParams();

  const domain = params.domain || searchParams.get("domain") || undefined;

  const advanced = pickBy({
    businessName: searchParams.get("businessName"),
    email: searchParams.get("email"),
    phone: searchParams.get("phone"),
    applicantFullName: searchParams.get("applicantFullName"),
    addressLine1: searchParams.get("addressLine1"),
    addressLine2: searchParams.get("addressLine2"),
    city: searchParams.get("city"),
    stateProvince: searchParams.get("stateProvince"),
    postalCode: searchParams.get("postalCode"),
    country: searchParams.get("country"),
  });

  if (!companyMatchProfilesQuery.data) return <Loading />;

  const matchProfileId =
    searchParams.get("riskProfile") === "default"
      ? undefined
      : (
          companyMatchProfilesQuery.data.filter(
            (p) => p.id === searchParams.get("riskProfile")
          )[0] || companyMatchProfilesQuery.data[0]
        )?.id;

  return (
    <LookupDomainBody
      matchProfiles={companyMatchProfilesQuery.data}
      matchProfileId={matchProfileId}
      domain={domain === "offline" ? undefined : domain}
      advanced={advanced}
      autoRun={searchParams.get("run") === "1"}
    />
  );
}

export const PrefetchLookupDomain = memo(function PrefetchLookupDomain() {
  const queryClient = useQueryClient();
  const api = useTrueBizApi();

  queryClient.prefetchQuery({
    queryKey: ["getCompanyMatchProfiles"],
    queryFn: () => api.getCompanyMatchProfiles(),
  });

  return null;
});
