import { object, string, array } from "yup";

export const MonitoringCreateFormSchema = object({
  domain: string()
    .transform((value, original) => {
      try {
        const stripped = (value as string).trim();
        try {
          const url = new URL(stripped);
          return url.hostname;
        } catch {
          return stripped;
        }
      } catch {
        return value;
      }
    })
    .test(
      "domain",
      "Enter a valid domain name (e.g. example.com)",
      function (value) {
        if (!value) {
          return false;
        }
        const regexp = new RegExp(
          /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/
        );
        return regexp.test(value);
      }
    )
    .required(),
  monitor_types: array(string().required()).min(1).required(),
  profile_id: string().nullable(),
  external_ref_id: string().nullable(),
});
