import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Form from "./Form";
import Success from "./Success";
import Error from "./Error";
import { useTrueBizApi } from "../../../../api";
import {
  APIMonitoringEnrollmentInput,
  APIMonitoringEnrollment,
} from "../../../../types/APIMonitoringEnrollment";

type ViewState = "form" | "success" | "error";

export interface Props {
  monitorDomain: string;
  onCancel: () => void;
  onDone: () => void;
  onEditMonitor: (
    domain: string,
    formData: APIMonitoringEnrollmentInput
  ) => Promise<APIMonitoringEnrollment>;
}

export default function MonitorEdit({
  onCancel,
  onDone,
  onEditMonitor,
  monitorDomain,
}: Props) {
  const api = useTrueBizApi();
  const [currentView, setCurrentView] = useState<ViewState>("form");
  const [submittedDomain, setSubmittedDomain] = useState("");
  const [reason, setReason] = useState("");

  const monitorQuery = useQuery({
    queryKey: ["getMonitor", monitorDomain],
    queryFn: useCallback(
      () => api.getMonitoringEnrollment(monitorDomain),
      [api, monitorDomain]
    ),
  });

  const matchProfilesQuery = useQuery({
    queryKey: ["getMatchProfiles"],
    queryFn: useCallback(() => api.getCompanyMatchProfiles(), [api]),
  });

  return (
    <Stack
      style={{
        width: "50vw",
        maxWidth: "425px",
        paddingLeft: "0.75em",
        paddingRight: "0.75em",
        paddingBottom: "0.75em",
        display: "flex",
        flex: 1,
      }}
    >
      <h2>
        <IconButton
          onClick={() => {
            onCancel();
          }}
        >
          <CloseIcon />
        </IconButton>
        Modify Enrollment
      </h2>
      {monitorQuery.isLoading || matchProfilesQuery.isLoading ? (
        <Stack
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : currentView === "form" && monitorQuery.data ? (
        <Form
          monitor={monitorQuery.data}
          onEditMonitor={async (formData) =>
            onEditMonitor(monitorDomain, formData)
          }
          onCancel={() => {
            onCancel();
          }}
          onSuccess={(domain) => {
            setSubmittedDomain(domain);
            setCurrentView("success");
          }}
          onError={(domain, reason) => {
            setSubmittedDomain(domain);
            setReason(reason || "");
            setCurrentView("error");
          }}
          matchProfiles={matchProfilesQuery.data || []}
        />
      ) : currentView === "success" ? (
        <Success domain={submittedDomain} onDone={onDone} />
      ) : currentView === "error" || monitorQuery.error ? (
        <Error
          domain={submittedDomain}
          reason={reason || String(monitorQuery.error) || ""}
          onDone={onDone}
        />
      ) : null}
    </Stack>
  );
}
