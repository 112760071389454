export enum DataSource {
  SOCIAL = "social",
  SUBMITTED = "submitted",
  WEBSITE = "website",
  OTHER = "other",
}

export default interface APIApplication {
  email_business_connections?: DataSource[] | null;
  phone_business_connections?: DataSource[] | null;
  address_business_connections?: DataSource[] | null;
  person_business_connections?: DataSource[] | null;
}
