import APIMonitoringAnomaly, {
  APIAnomalyLink,
  APIAnomalyStatus,
  APIAnomalyUser,
} from "./APIAnomaly";

export interface APIMonitoringEnrollmentInput {
  domain: string;
  monitor_types: APIMonitorType[];
  external_ref_id?: string | null | undefined;
  profile_id?: UUID | null | undefined;
}

export enum APIMonitoringEnrollmentOrderBy {
  ALERTS_ASC = "alerts",
  ALERTS_DESC = "-alerts",
  ASSIGNED_TO_ASC = "assigned_to",
  ASSIGNED_TO_DESC = "-assigned_to",
  CREATED_AT_ASC = "created_at",
  CREATED_AT_DESC = "-created_at",
  DOMAIN_ASC = "domain",
  DOMAIN_DESC = "-domain",
  LAST_RUN_ASC = "last_run",
  LAST_RUN_DESC = "-last_run",
  MONITOR_TYPES_ASC = "monitor_types",
  MONITOR_TYPES_DESC = "-monitor_types",
  NEXT_RUN_ASC = "next_run",
  NEXT_RUN_DESC = "-next_run",
  STATUS_ASC = "status",
  STATUS_DESC = "-status",
}

export type APIMonitoringEnrollmentFilterBy =
  | { column: "created"; operator: "before"; value: ISODateTime }
  | { column: "created"; operator: "after"; value: ISODateTime }
  | { column: "domain"; operator: "in"; value: string[] }
  | { column: "id"; operator: "in"; value: UUID[] }
  | { column: "anomaly"; operator: "in"; value: UUID[] }
  | { column: "anomaly_status"; operator: "in"; value: APIAnomalyStatus[] }
  | { column: "search"; operator: "eq"; value: string }
  | { column: "last_run"; operator: "before"; value: ISODateTime }
  | { column: "last_run"; operator: "after"; value: ISODateTime }
  | { column: "next_run"; operator: "before"; value: ISODateTime }
  | { column: "next_run"; operator: "after"; value: ISODateTime }
  | { column: "alerts"; operator: "in"; value: string[] }
  | { column: "monitor_types"; operator: "in"; value: string[] }
  | {
      column: "status";
      operator: "in";
      value: APIMonitorStatusType[];
    }
  | { column: "assigned_to"; operator: "in"; value: string[] };

export enum APIMonitorStatusType {
  ALERT = "io.truebiz.monitoring.monitor.status.alert",
  PENDING = "io.truebiz.monitoring.monitor.status.pending",
  ALERT_AND_PENDING = "io.truebiz.monitoring.monitor.status.alert_and_pending",
  ALL_CLEAR = "io.truebiz.monitoring.monitor.status.all_clear",
}

export enum APIMonitorActivityType {
  ENROLLED = "io.truebiz.monitoring.monitor.activity.enrolled",
  MODIFIED = "io.truebiz.monitoring.monitor.activity.modified",
  UNENROLLED = "io.truebiz.monitoring.monitor.activity.unenrolled",
  ENQUIRY = "io.truebiz.monitoring.monitor.activity.enquiry",
}

export enum APIMonitorType {
  BUSINESS_CLOSURE = "io.truebiz.monitoring.monitor.business-closure",
  CONNECTED_ENTITIES = "io.truebiz.monitoring.monitor.connected-entities",
  WEBSITE_CONTENT = "io.truebiz.monitoring.monitor.website-content",
}

export interface APIMonitorEnrolledActivity {
  id: UUID;
  created_at: ISODateTime;
  type: APIMonitorActivityType.ENROLLED;
}

export interface APIMonitorModifiedActivity {
  id: UUID;
  created_at: ISODateTime;
  type: APIMonitorActivityType.MODIFIED;
}

export interface APIMonitorUnenrolledActivity {
  id: UUID;
  created_at: ISODateTime;
  type: APIMonitorActivityType.UNENROLLED;
}

export interface APIMonitorEnquiryActivity {
  id: UUID;
  created_at: ISODateTime;
  type: APIMonitorActivityType.ENQUIRY;
  anomaly_count: number;
  anomaly_ids: UUID[];
}

export type APIMonitorActivity =
  | APIMonitorEnrolledActivity
  | APIMonitorModifiedActivity
  | APIMonitorUnenrolledActivity
  | APIMonitorEnquiryActivity;

export interface APIMonitorUnresolvedAnomalyAlert {
  id: UUID;
  type: string;
  display: string;
  description: string;
}

export interface APIMonitorUnresolvedAnomalyAssignment {
  id: UUID;
  anomaly_id: UUID;
  created_at: ISODateTime;
  assigned_to?: APIAnomalyUser | null | undefined;
  assigned_by: APIAnomalyUser;
}

export interface APIMonitoringEnrollment {
  external_ref_id?: UUID | null | undefined;
  domain: string;
  monitor_types: APIMonitorType[];

  created_at: ISODateTime;
  updated_at: ISODateTime;
  deleted_at?: ISODateTime | null | undefined;

  last_run?: ISODateTime | null | undefined;
  next_run?: ISODateTime | null | undefined;
  is_currently_running: boolean;
  status: APIMonitorStatusType;

  risk_profile?:
    | {
        id: UUID;
        name: string;
      }
    | null
    | undefined;

  unresolved_anomaly_count: number;
  unresolved_anomaly_alerts: APIMonitorUnresolvedAnomalyAlert[];
  unresolved_anomaly_assignments: APIMonitorUnresolvedAnomalyAssignment[];

  anomalies: APIMonitoringAnomaly[];
  next_anomalies_link: APIAnomalyLink | null | undefined;

  activity: APIMonitorActivity[];
  next_activity_link: APIAnomalyLink | null | undefined;
}
