export enum BusinessAttributeSection {
  LocationData = "location_data",
  SocialMedia = "social_media",
  WebsiteTraffic = "website_traffic",
  DomainAnalysis = "domain_analysis",
  WebserverAnalysis = "webserver_analysis",
  Ecommerce = "ecommerce",
  WebsiteContent = "website_content",
  KeyPeople = "key_people",
  Industry = "industry",
  Profile = "profile",
  Reviews = "reviews",
  ContactInfo = "contact_info",
  WebsitePolicyAnalysis = "website_policy_analysis",
  HistoricalScreenshots = "historical_screenshots",
  DuplicateWebsites = "duplicate_websites",
  ReferringSites = "referring_sites",
  ConnectedEntities = "connected_entities",
  NewsArticles = "news_articles",
  Application = "application",
}
