import { Typography, Box, FormControlLabel, Switch } from "@mui/material";

export default function RiskToggle({
  label,
  description,
  value,
  onToggle,
}: {
  label: string;
  description: string;
  value: boolean;
  onToggle: () => void;
}) {
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={onToggle}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={label}
      />
      <Typography variant="body2">{description}</Typography>
    </Box>
  );
}
