import { useEffect, useMemo, useState, ReactElement } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

import type {
  ColumnFiltersState,
  SortingState,
  RowSelectionState,
  RowData,
} from "@tanstack/react-table";

import {
  useQuery,
  keepPreviousData,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query";

import {
  Button,
  CircularProgress,
  Link,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import type { StackProps } from "@mui/material/Stack";

import { isEmpty, isEqual, sortBy, sum, uniq } from "lodash";
import cn from "classnames";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSearchParams } from "react-router-dom";
import GlobalFilter from "./GlobalFilter";
import ExportButton from "./ExportButton";
import AssignButton from "./AssignButton";
import ColumnFilterSort from "./ColumnFilterSort";
import SimpleSort from "./ColumnFilterSort/SimpleSort";
import ValuePickerFilter from "./ColumnFilterSort/ValuePickerFilter";
import DateThresholdFilter, {
  DateThresholdComparator,
  DateThresholdFilterValue,
} from "./ColumnFilterSort/DateThresholdFilter";

import {
  type SelectionAction,
  type TableColumn,
  type TableOrderBy,
  type TableFilterBy,
  type TableRow,
  TableStatus,
} from "./types";

import {
  columnDefinitions,
  DEFAULT_COLUMNS,
  DEFAULT_SELECTION_ACTIONS,
} from "./columnDefinitions";
import styles from "./Table.module.css";
import OverflowTooltip from "./OverflowTooltip";

import { ExportRow } from "./types";
import { useActiveMonitorsDrawer } from "../ActiveMonitorsDrawer";
import { useShowNetworkFailureToast } from "../../NetworkFailureToast";

// This feels hacky as fuck, but is the officially sanctioned way
// of getting the types to work. Woe to you who is trying to
// have multiple table instances with conflicting column defs ¯\_(ツ)_/¯
declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "valuePicker" | "dateThreshold";
    filterStickyValues?: string[];
    sortVariant?: "simple";
    resizable?: boolean;
    relativeSize?: number;
  }
}

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_DESCRIPTION = "issues";

export interface Props extends StackProps {
  getMonitors: (config: {
    limit: number;
    offset: number;
    orderBy: TableOrderBy[];
    filterBy: TableFilterBy[];
    fuzzySearch: string;
  }) => Promise<{
    items: TableRow[];
    count: number;
    limit: number;
    offset: number;
  }>;

  getIssuesByMonitorDomains: (
    domains: string[]
  ) => Promise<{ issues: ExportRow[] }>;

  getUniqueValuesForDomain?: () => Promise<string[]>;
  getUniqueValuesForMonitorTypes?: () => Promise<string[]>;
  getUniqueValuesForStatus?: () => Promise<string[]>;
  getUniqueValuesForAlerts?: () => Promise<string[]>;
  getUniqueValuesForAssignees?: () => Promise<string[]>;

  onAssignMonitorIssuesToUser?: ({
    monitorIds,
    userId,
  }: {
    monitorIds: UUID[];
    userId: UUID | null | undefined;
    userEmail: string | null | undefined;
  }) => Promise<void>;
  onOptimisticallyUpdateMonitorsAfterAssignment?: ({
    monitorIds,
    userId,
    userEmail,
    items,
  }: {
    monitorIds: UUID[];
    userId: UUID | null | undefined;
    userEmail: string | null | undefined;
    items: TableRow[];
  }) => TableRow[];
  onAssignMonitorIssuesToUserSuccess?: ({
    monitorIds,
    userId,
  }: {
    monitorIds: UUID[];
    userId: UUID | null | undefined;
  }) => Promise<void>;
  onAssignMonitorIssuesToUserError?: ({
    monitorIds,
    userId,
  }: {
    monitorIds: UUID[];
    userId: UUID | null | undefined;
  }) => Promise<void>;

  onOpenMonitorDetail?: (monitorId: UUID) => void;
  onEditMonitor?: (monitorId: UUID) => Promise<void>;
  onDeleteMonitor?: (monitorId: UUID) => Promise<void>;

  prefetch?: () => void;
  description?: string;
  columns?: TableColumn[];
  initialFilters?: TableFilterBy[];
  initialSorting?: TableOrderBy[];
  selectable?: boolean;
  selectionActions?: SelectionAction[];
  pageSize?: number;

  tableTitle?: ReactElement | null | undefined;
}

export default function MonitorsTable({
  getMonitors,
  getIssuesByMonitorDomains,

  getUniqueValuesForDomain,
  getUniqueValuesForMonitorTypes,
  getUniqueValuesForStatus,
  getUniqueValuesForAlerts,
  getUniqueValuesForAssignees,

  onAssignMonitorIssuesToUser,
  onOptimisticallyUpdateMonitorsAfterAssignment,
  onAssignMonitorIssuesToUserSuccess,
  onAssignMonitorIssuesToUserError,

  onOpenMonitorDetail,

  description = DEFAULT_DESCRIPTION,
  columns = DEFAULT_COLUMNS,
  initialFilters = [],
  initialSorting = [],
  selectable = true,
  selectionActions = DEFAULT_SELECTION_ACTIONS,
  pageSize = DEFAULT_PAGE_SIZE,
  sx = {},
  tableTitle,
  ...etc
}: Props) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const showToast = useShowNetworkFailureToast();
  const [params, setParams] = useSearchParams();

  const { editMonitor, deleteMonitor, createMonitor } =
    useActiveMonitorsDrawer();

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize });
  const [sorting, setSorting] = useState<SortingState>(
    initialSorting.map((orderBy) => {
      const desc = orderBy.startsWith("-");
      const id = orderBy.replace(/^-/, "");
      return { id, desc };
    })
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    initialFilters.map((initialFilter) => {
      let value: TableFilterBy["value"] | DateThresholdFilterValue =
        initialFilter.value;

      if (
        columnDefinitions[initialFilter.column]?.otherwise.meta
          ?.filterVariant === "dateThreshold"
      ) {
        value = {
          cutoffDate: initialFilter.value as Date,
          comparator: initialFilter.operator as DateThresholdComparator,
        };
      }

      return {
        id: initialFilter.column,
        value,
      };
    })
  );
  const [globalFilter, setGlobalFilter] = useState(params.get("search") || "");

  useEffect(() => {
    setParams((p) => {
      if (!globalFilter) {
        p.delete("search");
      } else {
        p.set("search", globalFilter);
      }

      return p;
    });
  }, [globalFilter, setParams]);

  const listQuery = useQuery({
    queryKey: [
      description,
      "monitors",
      "list",
      pagination,
      sorting,
      columnFilters,
      globalFilter,
    ],
    queryFn: () =>
      getMonitors({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        orderBy: sorting.map((col) =>
          col.desc ? `-${col.id}` : col.id
        ) as TableOrderBy[],
        filterBy: columnFilters.map((filter) => {
          const filterVariant =
            columnDefinitions[filter.id as TableColumn].otherwise?.meta
              ?.filterVariant;

          let value = filter.value;
          let operator = "eq";

          if (filterVariant === "valuePicker") {
            operator = "in";
          }

          if (filterVariant === "dateThreshold") {
            operator = (filter.value as any).comparator;
            value = (filter.value as any).cutoffDate;
          }

          return { column: filter.id, value, operator } as TableFilterBy;
        }),
        fuzzySearch: globalFilter,
      }),
    placeholderData: keepPreviousData,
    throwOnError: true,
  });

  const uniqueValuesQuery = useQuery({
    queryKey: [
      description,
      "monitors",
      "uniqueValuesForFilterPickerColumns",
      columns,
    ],
    queryFn: async () => {
      const [domain, status, alerts, assignee, monitor_types] =
        await Promise.all([
          getUniqueValuesForDomain?.(),
          getUniqueValuesForStatus?.(),
          getUniqueValuesForAlerts?.(),
          getUniqueValuesForAssignees?.(),
          getUniqueValuesForMonitorTypes?.(),
        ]);
      return {
        domain: domain || [],
        status: status || [],
        alerts: alerts || [],
        assignee: assignee || [],
        monitor_types: monitor_types || [],
      };
    },
  });

  const assignMonitorIssuesToUsersMutation = useMutation({
    mutationFn: async ({
      monitorIds,
      userId,
      userEmail,
    }: {
      monitorIds: string[];
      userId: string | null | undefined;
      userEmail: string | null | undefined;
    }) => {
      if (!onAssignMonitorIssuesToUser) return;
      await onAssignMonitorIssuesToUser({ monitorIds, userId, userEmail });
    },
    mutationKey: ["issues", "assignIssuesToUser"],
    onMutate: async (variables) => {
      if (!onOptimisticallyUpdateMonitorsAfterAssignment) return;

      await queryClient.cancelQueries({
        queryKey: [
          description,
          "monitors",
          "list",
          pagination,
          sorting,
          columnFilters,
          globalFilter,
        ],
      });
      const previous = queryClient.getQueryData([
        description,
        "monitors",
        "list",
        pagination,
        sorting,
        columnFilters,
        globalFilter,
      ]);

      return { previous };
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [description, "monitors"],
      });

      await onAssignMonitorIssuesToUserSuccess?.(variables);
    },
    onError: (err, variables, context) => {
      if (context?.previous) {
        queryClient.setQueryData(
          [
            description,
            "monitors",
            "list",
            pagination,
            sorting,
            columnFilters,
            globalFilter,
          ],
          context.previous
        );
      }

      showToast();
      onAssignMonitorIssuesToUserError?.(variables);
    },
  });

  const isTableLoading = !(listQuery.data && uniqueValuesQuery.data);

  const tableColumns = useMemo(
    () =>
      [...(selectable ? ["_select" as const] : []), ...columns].map(
        (columnName) =>
          isTableLoading
            ? columnDefinitions[columnName].whenLoading
            : columnDefinitions[columnName].otherwise
      ),
    [isTableLoading, columns, selectable]
  );

  const dataForLoadingSkeletonTable = useMemo(
    () => Array(pageSize).fill({}),
    [pageSize]
  );

  const table = useReactTable({
    data: isTableLoading ? dataForLoadingSkeletonTable : listQuery.data.items,
    getRowId: (originalRow) => originalRow.id,
    columns: tableColumns,
    enableRowSelection: !!selectable,

    state: {
      rowSelection,
      pagination,
      sorting,
      columnFilters,
    },

    getCoreRowModel: getCoreRowModel(),

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: (updaterOrValue) => {
      setSorting(updaterOrValue);
      setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
    },

    manualFiltering: true,
    onColumnFiltersChange: (updaterOrValue) => {
      setColumnFilters(updaterOrValue);
      setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
    },

    onRowSelectionChange: setRowSelection,

    columnResizeDirection: "ltr",
    columnResizeMode: "onChange",
  });

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 2, ...sx }} {...etc}>
        {tableTitle ? tableTitle : null}
        <GlobalFilter
          value={globalFilter}
          onChange={(v) => {
            setGlobalFilter(v);
          }}
          onClear={() => {
            setGlobalFilter("");
          }}
          description={description}
        />
        {isEmpty(rowSelection) && (
          <>
            <Button
              variant="outlined"
              size="small"
              sx={{
                display: "flex",
                flexShrink: 0,
              }}
              startIcon={<AddIcon />}
              onClick={() => {
                createMonitor({}, true);
              }}
            >
              Enroll a Merchant
            </Button>
          </>
        )}
        {!isEmpty(rowSelection) && (
          <>
            {selectionActions.includes("assign") && (
              <AssignButton
                ids={Object.keys(rowSelection)}
                onAssignIssues={async (
                  monitorIds: string[],
                  userId: string | null | undefined,
                  userEmail: string | null | undefined
                ) => {
                  try {
                    await assignMonitorIssuesToUsersMutation.mutateAsync({
                      monitorIds,
                      userId,
                      userEmail,
                    });
                    table.resetRowSelection();
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            )}
            {selectionActions.includes("export") && (
              <ExportButton
                domains={Object.keys(rowSelection)}
                getIssuesByDomains={getIssuesByMonitorDomains}
                onSuccess={() => table.resetRowSelection()}
                onError={() => {
                  showToast();
                }}
              />
            )}
            {selectionActions.includes("edit") &&
              Object.keys(rowSelection).length === 1 && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    display: "flex",
                    flexShrink: 0,
                  }}
                  startIcon={<EditIcon />}
                  onClick={async () => {
                    const selectedMonitorIds = Object.keys(rowSelection);
                    if (selectedMonitorIds.length !== 1) return;
                    const monitorId = selectedMonitorIds[0];
                    if (!monitorId) return;

                    try {
                      await editMonitor(monitorId, true);
                      table.resetRowSelection();
                    } catch (e) {
                      // pass
                    }
                  }}
                >
                  Modify
                </Button>
              )}
            {selectionActions.includes("delete") &&
              Object.keys(rowSelection).length === 1 && (
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  sx={{
                    display: "flex",
                    flexShrink: 0,
                  }}
                  startIcon={<DeleteIcon />}
                  onClick={async () => {
                    const selectedMonitorIds = Object.keys(rowSelection);
                    if (selectedMonitorIds.length !== 1) return;
                    const monitorId = selectedMonitorIds[0];
                    if (!monitorId) return;

                    try {
                      await deleteMonitor(monitorId, true);
                      table.resetRowSelection();
                    } catch (e) {
                      // pass
                    }
                  }}
                >
                  Unenroll
                </Button>
              )}
          </>
        )}
      </Stack>

      {/* TABLE BODY */}

      <div className={cn(styles.tableWrap)}>
        <table className={cn(styles.table)}>
          <thead className={cn(styles.left)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => {
                  const isResizable = !!header.column.columnDef.meta?.resizable;

                  const resizableColumns = table
                    .getAllColumns()
                    .filter((c) => c.columnDef.meta?.resizable);
                  const totalResizableWidthWeights = sum(
                    resizableColumns.map(
                      (c) => c.columnDef.meta?.relativeSize || 1
                    )
                  );

                  const unweightedWidth = header.getSize(); // assume this is just W / #resizableColumns
                  const totalWidth = unweightedWidth * resizableColumns.length;
                  const weightedColumnWidth =
                    totalWidth *
                    ((header.column.columnDef.meta?.relativeSize || 1) /
                      totalResizableWidthWeights);

                  return (
                    <th
                      key={header.id}
                      className={cn(styles.headercol, {
                        [styles.left]: !isResizable,
                        [styles.small]: !isResizable,
                      })}
                      style={{
                        width: isResizable ? weightedColumnWidth : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5em",
                            color:
                              !listQuery.isLoading &&
                              (!!header.column.getIsFiltered() ||
                                !!header.column.getIsSorted())
                                ? theme.palette.primary.main
                                : undefined,
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {(header.column.columnDef.meta?.sortVariant != null ||
                            header.column.columnDef.meta?.filterStickyValues !=
                              null) && (
                            <ColumnFilterSort
                              key={header.column.id}
                              disabled={!!listQuery.isLoading}
                              active={
                                !!header.column.getIsFiltered() ||
                                !!header.column.getIsSorted()
                              }
                              onReset={() => {
                                header.column.setFilterValue(undefined);
                                header.column.clearSorting();
                              }}
                            >
                              {header.column.columnDef.meta?.sortVariant ===
                                "simple" && (
                                <SimpleSort
                                  currentSort={
                                    header.column.getIsSorted() || null
                                  }
                                  onApply={(sort) => {
                                    if (!header.column.getCanSort()) return;

                                    if (!sort) {
                                      header.column.clearSorting();
                                      return;
                                    }

                                    if (sort === "desc") {
                                      header.column.toggleSorting(true, true);
                                      return;
                                    }

                                    if (sort === "asc") {
                                      header.column.toggleSorting(false, true);
                                      return;
                                    }
                                  }}
                                />
                              )}

                              {header.column.columnDef.meta?.filterVariant ===
                                "valuePicker" && (
                                <ValuePickerFilter
                                  allValues={
                                    uniqueValuesQuery.data &&
                                    header.column.id in uniqueValuesQuery.data
                                      ? uniqueValuesQuery.data[
                                          header.column
                                            .id as keyof typeof uniqueValuesQuery.data
                                        ]
                                      : undefined
                                  }
                                  stickyValues={
                                    header.column.columnDef.meta
                                      ?.filterStickyValues
                                  }
                                  selectedValues={
                                    header.column.getIsFiltered()
                                      ? (header.column.getFilterValue() as string[])
                                      : uniqueValuesQuery.data &&
                                        header.column.id in
                                          uniqueValuesQuery.data
                                      ? uniqueValuesQuery.data[
                                          header.column
                                            .id as keyof typeof uniqueValuesQuery.data
                                        ]
                                      : undefined
                                  }
                                  onApply={(
                                    selectedValues: string[] | null | undefined
                                  ) => {
                                    const allValues = sortBy(
                                      uniq(
                                        uniqueValuesQuery.data &&
                                          header.column.id in
                                            uniqueValuesQuery.data
                                          ? uniqueValuesQuery.data[
                                              header.column
                                                .id as keyof typeof uniqueValuesQuery.data
                                            ]
                                          : []
                                      )
                                    );

                                    const selected = sortBy(
                                      uniq(selectedValues || [])
                                    );

                                    if (isEqual(allValues, selected)) {
                                      header.column.setFilterValue(undefined);
                                    } else {
                                      // we can't use header.column.setFilterValue directly
                                      // because some of the defaults/truthiness assumptions
                                      // lead to wonky ux
                                      setColumnFilters((filters) => {
                                        const updatedFilters: ColumnFiltersState =
                                          [
                                            {
                                              id: header.column.id,
                                              value: selected,
                                            },
                                          ];

                                        for (const filter of filters) {
                                          if (filter.id !== header.column.id) {
                                            updatedFilters.push(filter);
                                          }
                                        }

                                        return updatedFilters;
                                      });
                                    }
                                  }}
                                />
                              )}
                              {header.column.columnDef.meta?.filterVariant ===
                                "dateThreshold" && (
                                <DateThresholdFilter
                                  cutoffDate={
                                    header.column.getIsFiltered()
                                      ? (header.column.getFilterValue() as any)
                                          ?.cutoffDate
                                      : undefined
                                  }
                                  comparator={
                                    header.column.getIsFiltered()
                                      ? (header.column.getFilterValue() as any)
                                          ?.comparator
                                      : undefined
                                  }
                                  onApply={(cutoffDate, comparator) => {
                                    if (!cutoffDate) {
                                      setColumnFilters((oldFilters) =>
                                        oldFilters.filter(
                                          (f) => f.id !== header.column.id
                                        )
                                      );
                                    } else {
                                      setColumnFilters((oldFilters) => {
                                        const newFilters = oldFilters.filter(
                                          (f) => f.id !== header.column.id
                                        );
                                        newFilters.push({
                                          id: header.column.id,
                                          value: { comparator, cutoffDate },
                                        });

                                        return newFilters;
                                      });
                                    }
                                  }}
                                />
                              )}
                            </ColumnFilterSort>
                          )}
                          {!!header.column.columnDef.meta?.resizable &&
                            i < headerGroup.headers.length - 1 && (
                              <div
                                onDoubleClick={() => header.column.resetSize()}
                                onMouseDown={header.getResizeHandler()}
                                onTouchStart={header.getResizeHandler()}
                                className={cn(styles.resizer, {
                                  [styles.isResizing]:
                                    header.column.getIsResizing(),
                                })}
                              />
                            )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className={styles.left}>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={cn({
                  [styles.selected]: row.getIsSelected(),
                  [styles.alert]: [
                    TableStatus.ALERT,
                    TableStatus.ALERT_AND_PENDING,
                  ].includes(row.getValue("status")),
                })}
                onClick={() => {
                  if (isTableLoading) return;
                  onOpenMonitorDetail?.(row.id);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <OverflowTooltip
                    key={cell.id}
                    tooltipEnabled={!isTableLoading}
                  >
                    <td>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  </OverflowTooltip>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!table.getRowModel().rows.length && (
          <Typography className={styles.emptyMessage} variant="body2">
            No monitors to show.
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                createMonitor({}, true);
              }}
            >
              Enroll a merchant
            </Link>
          </Typography>
        )}
      </div>

      {/* PAGINATION */}
      <div className={styles.pagination}>
        <Pagination
          page={pagination.pageIndex + 1}
          count={
            listQuery.data
              ? Math.ceil(listQuery.data.count / listQuery.data.limit)
              : undefined
          }
          onChange={(_, page) => {
            const pageIndex = Math.max(page - 1, 0);
            table.setPageIndex(pageIndex);
          }}
          size="small"
          showFirstButton
          showLastButton
        />
        <div className={styles.rowcounts}>
          {listQuery.data != null && (
            <>
              {table.getRowCount() > 0 && (
                <>
                  {pagination.pageIndex * pagination.pageSize + 1} -{" "}
                  {Math.min(
                    (pagination.pageIndex + 1) * pagination.pageSize,
                    listQuery.data.count
                  )}{" "}
                  of {listQuery.data.count}
                </>
              )}
              {!isEmpty(rowSelection) &&
                ` (${Object.keys(rowSelection).length} selected)`}
            </>
          )}
          {listQuery.isFetching && listQuery.isPlaceholderData && (
            <CircularProgress size={12} />
          )}
        </div>
      </div>
    </>
  );
}
