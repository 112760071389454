export const contentCategories = [
  { name: "Adult", subcats: ["Adult", "Dating"] },
  { name: "Gambling", subcats: ["Gambling", "Lottery", "Skill-Games"] },
  {
    name: "Medical",
    subcats: [
      "Pharmaceuticals",
      "Pharmaceuticals-Adjacent",
      "Nutraceuticals",
      "Telemedicine",
    ],
  },
  {
    name: "Money Services",
    subcats: [
      "High-Risk-Securities",
      "Financial-Services",
      "Cryptocurrencies",
      "Fundraising",
      "Credit-Services",
      "Insurance",
      "Investment",
    ],
  },
  {
    name: "Other",
    subcats: [
      "Adoption",
      "Animals",
      "Brand-Infringement-Risk",
      "Violence",
      "Negative-Option-Billing",
      "Minerals",
      "Cyberlockers",
      "Deceptive-Business-Practices",
      "Discrimination",
      "High-Risk-Subscriptions",
      "Store-of-Value",
      "Content-Creation-Platforms",
    ],
  },
  {
    name: "Professional Services",
    subcats: [
      "High-Risk-Legal-Services",
      "Private-Investigators",
      "Shipping",
      "Telemarketing",
      "Psychics-Fortune-Tellers",
      "Auctioneering",
      "Government-Services",
    ],
  },
  {
    name: "Restricted Goods",
    subcats: [
      "Illeagal-Drugs",
      "Weapons",
      "Fireworks",
      "Age-Restricted",
      "Dangerous-Goods",
      "Alcohol",
      "Tobacco",
      "Cannabis",
      "Cannabis-Adjacent",
    ],
  },
  {
    name: "Travel & Leisure",
    subcats: ["Timeshares", "High-Risk-Travel", "Travel-Reservation"],
  },
];
